import { connect } from 'react-redux'

import { GroupOrdersMaster } from '@components/groupOrders'

import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state: any) => {
  const { groupOrderPage, user } = state
  const { lastLoggedInHq } = user
  const account = state.groupOrderPage && state.groupOrderPage.account
  const routedFromSearch = !!account

  return {
    groupOrderPage,
    headquarter: lastLoggedInHq,
    client: account,
    routedFromSearch,
    ...state.router,
  }
}

const mapDispatchToProps = () => {
  const { RestService, UIService, GroupOrdersService, HeadquarterService } =
    services
  const {
    AsyncLoadAccounts,
    LoadAccount,
    GetGroupOrderById,
    SearchClientSettings,
    SaveClientSettings,
    SaveClientGlobalSettings,
    GetClientGlobalSettings,
    SaveStaffingFeeSettings,
    LoadHeadquarters,
    SearchSlackAuthData,
    SearchPopUpCustomers,
  } = coordinators
  const {
    pResponseGeneric,
    pResponseAccount,
    pResponseGroupOrderClientSetting,
    pResponseGroupOrderClientSettings,
  } = presenters.Api

  const { displayFailureMessage } = UIService.FlashMessage
  const loadAccounts = AsyncLoadAccounts({ RestService, pResponseGeneric })
  const loadAccount = LoadAccount({ RestService, pResponseAccount })
  const getGroupOrderById = GetGroupOrderById({ RestService, HandleError })
  const getClientGlobalSettings = GetClientGlobalSettings({
    RestService,
    HandleError,
  })
  const searchClientSettings = SearchClientSettings({
    RestService,
    GroupOrdersService,
    HandleError,
    pResponseGroupOrderClientSettings,
  })
  const saveClientSettings = SaveClientSettings({
    RestService,
    UIService,
    HandleError,
    pResponseGroupOrderClientSetting,
  })
  const saveClientGlobalSettings = SaveClientGlobalSettings({
    RestService,
    UIService,
    HandleError,
  })
  const saveStaffingFeeSettings = SaveStaffingFeeSettings({
    RestService,
    UIService,
    HandleError,
  })

  const loadHeadquarters = LoadHeadquarters({
    HeadquarterService,
    RestService,
    pResponseHeadquarters: pResponseGeneric,
    HandleError,
  })

  const searchSlackAuthData = SearchSlackAuthData({
    RestService,
    HandleError,
  })

  const searchPopUpCustomers = SearchPopUpCustomers({
    RestService,
    HandleError,
  })

  const {
    newClientSettings,
    newClientGlobalSettings,
    newScheduleSettings,
    setGroupOrderPage,
  } = GroupOrdersService

  return {
    loadAccounts,
    loadAccount,
    getGroupOrderById,
    searchClientSettings,
    saveClientSettings,
    saveClientGlobalSettings,
    getClientGlobalSettings,
    saveStaffingFeeSettings,
    loadHeadquarters,
    searchSlackAuthData,
    searchPopUpCustomers,
    displayFailureMessage,
    newClientSettings,
    newClientGlobalSettings,
    newScheduleSettings,
    setGroupOrderPage,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupOrdersMaster)
