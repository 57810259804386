import { connect } from 'react-redux'

import { EditAccountModal } from '@components/account/edit'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError, ValidateAndReturnError } from '@coordinators/composed'
import { config } from 'hungry-core2'

const mapStateToProps = (state) => {
  const {
    editAccount,
    referredBy,
    referralPartners,
    hubspotCompany,
    hubspotCompanies,
    hubspotContact,
    hubspotContacts,
    user,
    dinerProfiles,
    router,
  } = state
  const { show } = state.editAccountModal
  const errors = state.errors.accountModal || {}

  const port = router.location.port ? `:${router.location.port}` : ''
  const host =
    config.admin_url + (config.admin_url === 'http://localhost' ? port : '')

  return {
    account: editAccount,
    dinerProfiles,
    hubspotCompany,
    hubspotCompanies,
    hubspotContact,
    hubspotContacts,
    errors,
    referredBy,
    referralPartners,
    show,
    currentUser: user,
    host,
  }
}

const mapDispatchToProps = () => {
  const {
    AccountService,
    HubspotContactService,
    HubspotCompanyService,
    GroupOrdersService,
    RestService,
    ReferralService,
    UIService,
  } = services
  const {
    CloseEditAccount,
    DelayedUpdateAccount,
    DeleteAccount,
    AsyncLoadAccountContacts,
    AsyncLoadAccounts,
    LoadAccountMarketplaceUsers,
    LoadMktplaceUsers,
    LoadReferralPartners,
    LoadHubspotSingle,
    LoadOrSearchHubspotList,
    SaveAccount,
    SaveAdhocPaymentMethod,
    SearchClientSettings,
    SearchHubspotCompanyById,
    SetHubspotRelation,
    CreateAccountDocument,
    LoadAccountExecutives,
    UpdateClientSettingsClient,
    UploadAccountLogoImage,
    ValidateClientAutoInvBatchable,
    UpdateAccountContact,
    UpdateAccountAddress,
    UpdatePaymentMethod,
    RemoveAccountAddress,
    RemoveAccountContact,
    MarkDefaultAccountAddress,
    MarkDefaultAccountContact,
  } = coordinators
  const { pFindIdenticalPaymentMethod, pStateToReduxAccount } = presenters
  const {
    pContactToAccountingContact,
    pGetAutoInvoiceBillingAddress,
    pRequestUpdateAccount,
    pRequestUpsertAccountingClient,
    pResponseAccount,
    pResponseEditAccount,
    pResponseEditClientAccounting,
    pResponseGeneric,
    pResponseHubspotSingle,
    pResponseHubspotCompanies,
    pResponseHubspotContacts,
    pRequestSaveAdhocPaymentMethod,
    pRequestHubspotList,
    pResponseAccountExecutives,
    pResponseAccountMpUsers,
    pResponseGroupOrderClientSettings,
    pRequestUpdateAccountContact,
    pRequestUpdateAccountPaymentMethod,
  } = presenters.Api
  const { vAccountAddress } = presenters.Validation
  const clearErrors = UIService.Errors.clear
  const close = CloseEditAccount({ AccountService, UIService })
  const delayedUpdateAccount = DelayedUpdateAccount({
    AccountService,
    UIService,
    pStateToReduxAccount,
  })
  const deleteAccount = DeleteAccount({
    AccountService,
    RestService,
    UIService,
  })
  const loadAccountContacts = AsyncLoadAccountContacts({
    AccountService,
    RestService,
    pResponseGeneric,
  })
  const loadReferralPartners = LoadReferralPartners({
    ReferralService,
    RestService,
    pResponseGeneric,
  })
  const saveAccount = SaveAccount({
    AccountService,
    RestService,
    UIService,
    pRequestUpdateAccount,
    pRequestUpsertAccountingClient,
    pResponseAccount,
    pResponseEditClientAccounting,
    HandleError,
  })
  const createAccountDocument = CreateAccountDocument({
    RestService,
    HandleError,
  })
  const loadAccountExecutives = LoadAccountExecutives({
    RestService,
    pResponseAccountExecutives,
  })
  const loadAccountMarketplaceUsers = LoadAccountMarketplaceUsers({
    RestService,
    pResponseGeneric: pResponseAccountMpUsers,
    HandleError,
  })
  const loadMktplaceUsers = LoadMktplaceUsers({
    AccountService,
    RestService,
    pResponseGeneric: pResponseAccountMpUsers,
  })

  const loadHubspotContact = LoadHubspotSingle({
    RestService,
    HubspotService: HubspotContactService,
    pResponseHubspotSingle,
    endpoint: 'contacts',
  })
  const loadHubspotContacts = LoadOrSearchHubspotList({
    RestService,
    HubspotService: HubspotContactService,
    pRequestHubspotList,
    pResponseHubspotList: pResponseHubspotContacts,
    endpoint: 'contacts',
  })
  const updateHubspotContactRef = SetHubspotRelation({
    RestService,
    HubspotService: HubspotContactService,
    pResponseHubspotSingle,
    endpoint: 'contacts',
  })
  const loadHubspotCompany = LoadHubspotSingle({
    RestService,
    HubspotService: HubspotCompanyService,
    pResponseHubspotSingle,
    endpoint: 'companies',
  })
  const loadHubspotCompanies = LoadOrSearchHubspotList({
    RestService,
    HubspotService: HubspotCompanyService,
    pRequestHubspotList,
    pResponseHubspotList: pResponseHubspotCompanies,
    endpoint: 'companies/text-search',
  })
  const loadHubspotCompanyById = SearchHubspotCompanyById({
    RestService,
    HubspotService: HubspotCompanyService,
    pResponseHubspotList: pResponseHubspotCompanies,
    endpoint: 'companies/search',
    HandleError,
  })
  const updateHubspotCompanyRef = SetHubspotRelation({
    RestService,
    HubspotService: HubspotCompanyService,
    pResponseHubspotSingle,
    endpoint: 'companies',
  })
  const savePaymentMethod = SaveAdhocPaymentMethod({
    RestService,
    pFindIdenticalPaymentMethod,
    pRequestSaveAdhocPaymentMethod,
    pResponseGeneric,
    HandleError,
  })
  const searchClientSettings = SearchClientSettings({
    RestService,
    GroupOrdersService,
    HandleError,
    pResponseGroupOrderClientSettings,
  })
  const loadAccounts = AsyncLoadAccounts({ RestService, pResponseGeneric })
  const updateClientSettingsClient = UpdateClientSettingsClient({
    RestService,
    HandleError,
  })
  const uploadAccountLogoImage = UploadAccountLogoImage({
    RestService,
    HandleError,
  })
  const flashError = (message) =>
    UIService.FlashMessage.displayFailureMessage(message)
  const flashWarning = (message) =>
    UIService.FlashMessage.displayWarningMessage(message)
  const validateAddress = ValidateAndReturnError({
    validator: vAccountAddress,
    namespace: 'accountModal',
    doFlash: false,
  })
  const validateClientAutoInvBatchable = ValidateClientAutoInvBatchable({
    RestService,
    HandleError,
  })

  const updateAccountContact = UpdateAccountContact({
    AccountService,
    RestService,
    pRequestUpdateAccountContact,
    pResponseEditAccount,
    pResponseEditClientAccounting,
    HandleError,
  })
  const updateAccountAddress = UpdateAccountAddress({
    RestService,
    AccountService,
    pResponseEditAccount,
    pResponseEditClientAccounting,
    HandleError,
  })

  const updatePaymentMethod = UpdatePaymentMethod({
    RestService,
    AccountService,
    pRequestUpdateAccountPaymentMethod,
    pResponseEditAccount,
    pResponseEditClientAccounting,
    HandleError,
  })

  const deleteAccountAddress = RemoveAccountAddress({
    RestService,
    AccountService,
    UIService,
    pResponseEditAccount,
    pResponseEditClientAccounting,
    HandleError,
  })

  const deleteAccountContact = RemoveAccountContact({
    RestService,
    AccountService,
    UIService,
    pResponseEditAccount,
    pResponseEditClientAccounting,
    HandleError,
  })

  const markDefaultAccountAddress = MarkDefaultAccountAddress({
    RestService,
    AccountService,
    pResponseEditAccount,
    pResponseEditClientAccounting,
    HandleError,
  })

  const markDefaultAccountContact = MarkDefaultAccountContact({
    RestService,
    AccountService,
    pResponseEditAccount,
    pResponseEditClientAccounting,
    HandleError,
  })

  return {
    flashError,
    flashWarning,
    clearErrors,
    close,
    delayedUpdateAccount,
    deleteAccount,
    deleteAccountAddress,
    deleteAccountContact,
    getAutoInvoiceBillingAddress: pGetAutoInvoiceBillingAddress,
    loadAccountMarketplaceUsers,
    loadAccountContacts,
    loadAccounts,
    loadHubspotCompany,
    loadHubspotCompanyById,
    loadHubspotCompanies,
    loadHubspotContact,
    loadHubspotContacts,
    loadMktplaceUsers,
    loadReferralPartners,
    markDefaultAccountAddress,
    markDefaultAccountContact,
    pContactToAccountingContact,
    pRequestUpdateAccount,
    saveAccount,
    savePaymentMethod,
    searchClientSettings,
    updateHubspotCompanyRef,
    updateHubspotContactRef,
    createAccountDocument,
    loadAccountExecutives,
    uploadAccountLogoImage,
    updateAccountContact,
    updateAccountAddress,
    updateClientSettingsClient,
    updatePaymentMethod,
    validateAddress,
    validateClientAutoInvBatchable,
  }
}
//

export default connect(mapStateToProps, mapDispatchToProps)(EditAccountModal)
