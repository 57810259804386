export const SearchClientSettings =
  ({
    RestService,
    GroupOrdersService,
    HandleError,
    pResponseGroupOrderClientSettings,
  }) =>
  async ({ clientPin }) => {
    try {
      const settings = await RestService.get('/group-orders/client-settings', {
        clientPin,
      })
      const result = pResponseGroupOrderClientSettings(settings)
      GroupOrdersService.setClientSettings(result)

      return result
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const SaveClientSettings =
  ({ RestService, UIService, HandleError, pResponseGroupOrderClientSetting }) =>
  async (clientSettings, clientName) => {
    let result
    try {
      if (clientSettings.id) {
        result = await RestService.put(
          `/group-orders/client-settings/${clientSettings.id}`,
          clientSettings,
        )
      } else {
        result = await RestService.post(
          '/group-orders/client-settings',
          clientSettings,
        )
      }
    } catch (error) {
      HandleError({ error })

      return false
    }
    UIService.FlashMessage.displaySuccessMessage(
      `${clientName} Group Order Settings successfully updated`,
    )

    return pResponseGroupOrderClientSetting(result)
  }

export const UpdateClientSettingsClient =
  ({ RestService, HandleError }) =>
  async (settingsId, clientName, client) => {
    try {
      await RestService.put(
        `/group-orders/client-settings/${settingsId}/client`,
        client,
      )
    } catch (error) {
      HandleError({ error })

      return false
    }

    return true
  }

export const DeleteClientSettings =
  ({ RestService, UIService, HandleError }) =>
  async (id, clientName) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete Group Order Settings for "${clientName}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/group-orders/client-settings/${id}`)
      } catch (error) {
        HandleError({ error })

        return false
      }

      return true
    }

    return false
  }

export const SearchSlackAuthData =
  ({ RestService, HandleError }) =>
  async ({ customerIds, teamId }) => {
    try {
      const results = await RestService.post('/slack/search_slack_auth_data', {
        customerIds,
        teamId,
      })

      return [results, '']
    } catch (error) {
      HandleError({ error })

      return [[], String(error)]
    }
  }
