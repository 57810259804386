import React, { useState, useEffect, useCallback, ChangeEvent } from 'react'
import Moment from 'moment-timezone'

import { Modal } from '@components/common/modal'
import { FlexContainer, YSpacing, DividerLine } from '@components/common'
import { Label, MultiSelectOption } from '@res/styledComponents/index'
import {
  MarketDropdown,
  AutocompleteInput,
  TextInput,
  Checkbox,
  Button,
  Dropdown,
} from '@components/common/form'

import {
  ClientReportingProgram,
  Account,
  LoadClientReportingProgramsParams,
  IndexHeadquarter,
  ApiDinerProfile,
  ClientSettings,
} from '@types'

interface EditReportingProgramModalProps {
  account: Account
  currentUser: { firstName: string; lastName: string }
  program: ClientReportingProgram
  clientSettings: ClientSettings[]

  saveClientReportingProgram: (
    data: ClientReportingProgram,
  ) => Promise<ClientReportingProgram | null>
  loadClientReportingPrograms: (
    params: LoadClientReportingProgramsParams,
  ) => Promise<ClientReportingProgram[]>
  loadHeadquarters: () => Promise<IndexHeadquarter[]>
  loadDinerProfiles: (
    params: Record<string, string | number[] | null>,
  ) => Promise<ApiDinerProfile[]>
  closeModal: () => void
  flashError: (message: string) => void
}

const EditReportingProgramModal: React.FC<EditReportingProgramModalProps> = ({
  account,
  currentUser,
  program: reportingProgram,
  clientSettings,
  saveClientReportingProgram,
  loadClientReportingPrograms,
  loadHeadquarters,
  loadDinerProfiles: _loadDinerProfiles,
  closeModal,
  flashError,
}) => {
  const [program, setProgram] = useState<ClientReportingProgram>({
    ...reportingProgram,
  })
  const [dinerProfiles, setDinerProfiles] = useState<ApiDinerProfile[]>([])
  const [hqs, setHqs] = useState<IndexHeadquarter[]>([])
  const [hqIds, setHqIds] = useState<number[] | null>(null)

  useEffect(() => {
    const loadHqs = async () => {
      const hqs = await loadHeadquarters()
      setHqs(hqs)
    }
    loadHqs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (program.dinerProfileIds.length == 0) {
      return
    }

    const loadProfiles = async () => {
      const profiles = await _loadDinerProfiles({
        ids: program.dinerProfileIds.join(','),
      })
      setDinerProfiles(profiles)
    }
    loadProfiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadDinerProfiles = useCallback(
    async (search: string) => {
      if (!hqIds) {
        return []
      }

      const profiles = await _loadDinerProfiles({
        search,
        hqIds,
      })

      return profiles
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hqIds],
  )

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value
    setProgram({ ...program, name })
  }

  const onSelectDinerProfile = (profile: ApiDinerProfile) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const newIds = [...program.dinerProfileIds, profile.id!]

    setProgram({
      ...program,
      dinerProfileIds: newIds,
    })
    setDinerProfiles([...dinerProfiles, profile])
  }

  const onRemoveDinerProfile = (id: string) => {
    const newIds = program.dinerProfileIds.filter(
      (profileId) => profileId !== id,
    )
    const newProfiles = dinerProfiles.filter((profile) => profile.id !== id)

    setProgram({
      ...program,
      dinerProfileIds: newIds,
    })
    setDinerProfiles(newProfiles)
  }

  const onSave = async () => {
    if (program.name === '' || program.name.trim().length === 0) {
      flashError('Please enter a name for the reporting program')

      return
    }
    program.lastUpdatedBy = `${currentUser.firstName} ${currentUser.lastName}`

    const uProgram = await saveClientReportingProgram(program)
    if (uProgram) {
      await loadClientReportingPrograms({ clientId: account.id })
      closeModal()
    }
  }

  const settingsName = (clientSettings: ClientSettings) => {
    return clientSettings.name === '' ? 'Default Location' : clientSettings.name
  }

  const settingsMultiSelectOptions = () => {
    const jsx: JSX.Element[] = []

    program.clientSettingsIds.forEach((id) => {
      const setting = clientSettings.find((setting) => setting.id === id)
      if (!setting) {
        return
      }

      jsx.push(
        <MultiSelectOption
          key={`client-setting-${id}`}
          value={id}
          onClick={() => {
            const newIds = program.clientSettingsIds.filter(
              (settingId) => settingId !== id,
            )
            setProgram({ ...program, clientSettingsIds: newIds })
          }}
        >
          {settingsName(setting)}
        </MultiSelectOption>,
      )
    })

    return jsx
  }

  return (
    <Modal
      title={
        program.id
          ? `Edit Reporting Program: ${program.name}`
          : 'Add New Reporting Program'
      }
      hideModal={closeModal}
      color="#001940"
      width="900px"
    >
      <FlexContainer flexDirection="column" width="100%">
        <FlexContainer
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Label fontSize="16px">General Information</Label>
          {program.id && (
            <Label fontSize="12px">
              Last Updated:{' '}
              {Moment(program.updatedAt).format('MM/DD/YYYY h:mm a')}
            </Label>
          )}
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer flexDirection="row" width="100%" alignItems="center">
          <FlexContainer flexDirection="row" width="100%">
            <div className="w-full">
              <Label>Reporting Program Name</Label>
              <TextInput
                testId="reporting-program-name"
                value={program.name}
                onChange={onChangeName}
                placeholder="Reporting Program Name"
              />
            </div>
            <p style={{ marginLeft: '10%', fontStyle: 'italic' }}>
              Create a distinct program name that best descibes your
              client&apos;s program they have with HUNGRY in the view of how
              they would like to review their reporting data on the Client Admin
              Dashboard.
            </p>
          </FlexContainer>
        </FlexContainer>
        <YSpacing height="20px" />
        <Checkbox
          testId="reporting-program-default"
          label="Default?"
          checked={program.isDefault}
          onChange={() =>
            setProgram({ ...program, isDefault: !program.isDefault })
          }
        />
        <DividerLine margin="20px 0" />
        <FlexContainer flexDirection="column" width="100%">
          <Label fontSize="16px">Linked Diner Profiles</Label>
          <YSpacing height="10px" />
          <FlexContainer flexDirection="row" justifyContent="space-between">
            <div style={{ width: '33%' }}>
              <MarketDropdown
                options={hqs}
                onSelectHq={(id) => {
                  const hqs = hqIds ? [...hqIds, id] : [id]
                  setHqIds(hqs)
                }}
                onDeselectHq={(id) => {
                  const hqs = hqIds ? hqIds.filter((hqId) => hqId !== id) : null
                  setHqIds(hqs)
                }}
                selectedOptions={hqIds || []}
              />
            </div>
            <div style={{ width: '33%' }}>
              <AutocompleteInput
                testId="reporting-program-autocomplete"
                alreadySelectedIDs={program.dinerProfileIds}
                clearOnFocus={true}
                disabled={!hqIds}
                placeholder="Select market, then search by name"
                loaderFunction={({ search }: { search: string }) =>
                  loadDinerProfiles(search)
                }
                onSelect={onSelectDinerProfile}
              />
            </div>
          </FlexContainer>
          <YSpacing height="10px" />
          <FlexContainer>
            {dinerProfiles.map((profile, i) => (
              <MultiSelectOption
                key={profile.id || `diner-profile-${i}`}
                onClick={() => profile.id && onRemoveDinerProfile(profile.id)}
              >
                {profile.name}
              </MultiSelectOption>
            ))}
          </FlexContainer>
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer flexDirection="column" width="100%">
          <Label fontSize="16px">Linked Group Order Locations</Label>
          <YSpacing height="10px" />
          <FlexContainer flexDirection="row" justifyContent="space-between">
            <Dropdown
              width="33%"
              onChange={({ target: { value } }) => {
                const newIds = [...program.clientSettingsIds, value]
                setProgram({ ...program, clientSettingsIds: newIds })
              }}
            >
              <option value="">Select Group Order Location</option>
              {clientSettings
                .filter(
                  (settings) =>
                    !program.clientSettingsIds.includes(settings.id),
                )
                .map((setting) => (
                  <option key={setting.id} value={setting.id}>
                    {settingsName(setting)}
                  </option>
                ))}
            </Dropdown>
          </FlexContainer>
          <YSpacing height="10px" />
          <FlexContainer flexDirection="row" flexWrap="wrap">
            {settingsMultiSelectOptions()}
          </FlexContainer>
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between" width="100%">
          <Button label="Cancel" onClick={closeModal} />
          <Button
            label="Save"
            backgroundColor="#6580CC"
            testId="save-reporting-program"
            onClick={onSave}
          />
        </FlexContainer>
      </FlexContainer>
    </Modal>
  )
}

export default EditReportingProgramModal
