/* eslint-disable prefer-destructuring */
import React, { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import { IconContext } from 'react-icons'
import { BsArrowsExpand } from 'react-icons/bs'
import { AuthorizedInteractable } from '@containers/common/auth'
import { InvoiceRefundModal } from '@containers/invoice'
import { InvoiceVoidModal } from '@containers/invoice'
import { InvoiceCustomPayModal } from '@containers/invoice'
import { AutocompleteInput } from '@containers/common/form'
import { DateInput } from '@components/common/form'
import Checkbox from '@components/common/form/Checkbox'
import DividerLine from '@components/common/DividerLine'
import { Label } from '@res/styledComponents/index'
import { allOrderTypes } from './constants'
import { NetPaymentDays } from '../../../constants'
import { colors, ACH_CHECK, SETTLED } from '../../../constants'
import trash from '@res/images/trash-icon.png'
import {
  Button,
  CurrencyInput,
  Dropdown,
  LabelInfo,
  Input,
} from '@components/common/form'
import Modal from '@components/common/modal/Modal'
import Table from '@components/common/Table'
import XSpacing from '@components/common/XSpacing'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { OrderFormSectionTitle } from '@res/styledComponents/index'
import TooltipModal from '@components/common/modal/TooltipModal'
import { ModalSectionTitle } from '@res/styledComponents/index'
import Textarea from '../common/form/TextArea'
import LoadingIndicator from '../common/LoadingIndicator'
import {
  calcOrderTotalPaid,
  calcOrderTotalPaidOrBatchedAmt,
  calcOrderTotalUnpaidUnbatchedAmount,
  calcOrderTotalUnpaidBatchedAmt,
  calculatePaymentAmounts,
  calcPercentPaidOrInvoiced,
  getOrderBals,
  getOrderBatchInvs,
  getOrderType,
  roundUp as roundAmt,
} from '@utils'

const TotalUnaccountedOption = 'Bill Total Un-Accounted'

const DefaultPaymentOptions = [TotalUnaccountedOption]

const calcPercentage = (order, orderType) => {
  let percent
  if (['Pop Up', 'Group Order'].includes(orderType)) {
    percent = 1.0
  } else {
    percent = order.invoicedAmount
      ? 1 - order.invoicedAmount / order.total
      : 1.0
  }

  return percent
}

const calcEffectiveDate = (invoice) => {
  if (invoice.effectiveDateOverride) {
    return Moment(invoice.effectiveDateOverride)
  } else {
    return Moment().set({ hour: 12 })
  }
}

const calculateDueDate = (client, invoice) => {
  // New Invoice
  if (!invoice.id && !invoice.effectiveDateOverride) {
    return Moment().set({ hour: 12 }).add(client.dueDateOffset, 'days')
  } else if (invoice.effectiveDateOverride) {
    return Moment(invoice.effectiveDateOverride).add(
      client.dueDateOffset,
      'days',
    )
  } else {
    return Moment(invoice.createdAt).add(client.dueDateOffset, 'days')
  }
}

const displayAssociatedInvsWarning = (order, displayWarningMessage) => {
  const batchInvMap = (order.orderPayments || [])
    .filter((payment) => payment.paymentInvoice.invoiceNumber.includes('MINV'))
    .reduce((acc, payment) => {
      acc[payment.paymentInvoice.id] = payment.paymentInvoice.invoiceNumber

      return acc
    }, {})

  let message = `Order ${order.orderNumber} is already fully invoiced.`
  if (Object.values(batchInvMap).length > 0) {
    message += `\n Batch Invoices: ${Object.values(batchInvMap).join(', ')}`
  }

  displayWarningMessage(message)
}

const getOrderDefaultInvAmt = (
  accOrder,
  allInvoicePayments,
  allInvoiceRemovedPayments,
  displayWarningMessage,
) => {
  const uninvoicedAmount = calcOrderTotalUnpaidUnbatchedAmount(
    accOrder,
    allInvoiceRemovedPayments,
    allInvoicePayments,
  )
  if (uninvoicedAmount <= 0) {
    displayAssociatedInvsWarning(accOrder, displayWarningMessage)
  }

  return uninvoicedAmount
}

const validateOverInvoicing = (payments, removedPayments, invoicedOrders) => {
  for (let i = 0; i < payments.length; i++) {
    const payment = payments[i]
    const order = invoicedOrders.find((o) => o.id === payment.paymentFor.id)
    if (!order) {
      return 'Order not found for payment'
    }
    const invAmount = calcOrderTotalPaidOrBatchedAmt(
      order,
      removedPayments,
      payments,
    )
    const { total: orderTotal } = getOrderBals(order)
    if (invAmount > orderTotal) {
      return `Order ${order.orderNumber} has been over invoiced. Please adjust.`
    }
  }

  return undefined
}

const checkIfPaidFromInvoice = (payments, orderId) => {
  for (let i = 0; i < payments.length; i++) {
    const payment = payments[i]
    if (payment.isPaid && payment.paymentFor.id === orderId) {
      return true
    }
  }

  return false
}

class InvoicesModal extends Component {
  state = {
    allOrdersLoading: false,
    collapseTxnPaymentsMap: {},
    editingBilling: false,
    existingInvoice: false,
    from: '',
    fullBillingAddress: '',
    invoice: undefined,
    invoicedOrders: [], // orders added to invoice
    isARInvoice: false,
    isLoading: false,
    orders: [], // searched orders
    orderCount: 0,
    orderTypes: [],
    overPayOrdersMap: {},
    pageCount: 0,
    payments: [],
    refundableTxns: [],
    removedPayments: [],
    rootClient: undefined,
    search: '',
    searchLimit: 10,
    searchPage: 0,
    selectedClient: undefined,
    selectedAccountingClient: undefined,
    showRefundModal: false,
    showTxnTable: false,
    showVoidModal: false,
    to: '',
    updatedTransactions: [],
    voidableTxns: [],
  }
  saving = false
  taxTimer = undefined

  componentWillMount() {
    const { newInvoice, invoice, arClientId, preloadOrders } = this.props
    const overPayOrdersMap = {}
    if (invoice) {
      this.checkInvoiceTxnStatuses(invoice)
      if (invoice.buyer) {
        this.loadClient(invoice.buyer.id)
      }
      if (
        !invoice.contactEmail ||
        !invoice.contactName ||
        !invoice.contactPhone
      ) {
        invoice.contactName = invoice.buyer && invoice.buyer.contactName
        invoice.contactEmail = invoice.buyer && invoice.buyer.email
        invoice.contactPhone = invoice.buyer && invoice.buyer.phoneNumber
      }
      if (Array.isArray(invoice.overPayOrders)) {
        invoice.overPayOrders.forEach((order) => {
          overPayOrdersMap[order] = true
        })
      }

      //can be a result of a discount added after being paid
      const overPaymentMap = this.checkForOverPayments(invoice.payments)

      const invoicedOrdersMap = invoice.payments.reduce((acc, pay) => {
        if (!acc[pay.paymentFor.id]) {
          const order = pay.paymentFor
          acc[pay.paymentFor.id] = order
        }

        return acc
      }, {})

      const allOrdersFullyPaid = Object.values(invoicedOrdersMap).every(
        (o) => o.isPaid,
      )
      const hasPaidOrder = invoice.payments
        .map((pmt) => pmt.paymentFor)
        .some((order) => order.isPaid)
      let markFinalReason
      if (hasPaidOrder && Object.keys(overPayOrdersMap).length) {
        markFinalReason =
          'there is atleast one order that is already paid & paying this invoice will result in an overpayment on an order.'
      } else if (hasPaidOrder) {
        markFinalReason =
          'there is atleast one order that is already paid on this invoice.'
      } else if (Object.keys(overPayOrdersMap).length) {
        markFinalReason =
          'paying this invoice will result in an overpayment on atleast one order.'
      }

      //Invoice was marked as final & another invoice may have been paid
      //causing this invoice to no longer be final
      if (invoice.isFinal && markFinalReason) {
        ;(async () => {
          const inv = await this.props.saveInvoice(
            {
              invoice: { ...invoice, isFinal: false },
              payments: invoice.payments,
              removedPayments: [],
            },
            false,
            false,
          )
          if (inv) {
            this.setState({
              invoice: inv,
              payments: invoice.payments,
              overPayOrdersMap,
              invoicedOrders: invoice.payments.map((pmt) => pmt.paymentFor),
              existingInvoice: true,
              fullBillingAddress: invoice.fullBillingAddress,
              markFinalReason,
              wasFinal: true,
            })
          }
        })()
      }

      const initialResolvedByEmail = invoice.resolvedByEmail
        ? invoice.resolvedByEmail
        : null

      this.setState({
        initialResolvedByEmail,
        allOrdersFullyPaid,
        existingInvoice: true,
        fullBillingAddress: invoice.fullBillingAddress,
        invoicedOrders: Object.values(invoicedOrdersMap),
        invoice,
        overPaymentMap,
        overPayOrdersMap,
        payments: invoice.payments,
        markFinalReason,
      })
    } else {
      this.setState({ invoice: newInvoice(), initialResolvedByEmail: null })
      if (arClientId && arClientId !== '') {
        this.initARClient(arClientId, preloadOrders)
      } else {
        this.searchOrders()
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    const { arClientId } = this.props
    if (prevProps.arClientId !== arClientId && arClientId !== '') {
      this.initARClient(arClientId)
    }
  }

  checkIsEditable = () => {
    const { isAuthorized, user } = this.props
    const { invoice } = this.state

    if (!invoice.id) {
      return true
    }

    return isAuthorized(user, ['master admin'])
  }

  checkIsResolvable = () => {
    const { isAuthorized, isAuthorizedWtihAll, user } = this.props
    const { invoice } = this.state

    if (invoice.transactions?.length > 0) {
      return false
    }

    return (
      isAuthorized(user, ['master admin', 'finance']) ||
      isAuthorizedWtihAll(user, ['sales lead', 'chef lead'])
    )
  }

  checkForOverPayments = (payments) => {
    const overPaymentMap = {}
    const orderTotalMap = {}
    const orderPmtsTotal = {}

    for (const pay of payments) {
      if (!pay.isPaid) {
        continue
      }
      orderTotalMap[pay.paymentFor.id] = getOrderBals(pay.paymentFor).total
      if (!orderPmtsTotal[pay.paymentFor.id]) {
        orderPmtsTotal[pay.paymentFor.id] = pay.amount
      } else {
        orderPmtsTotal[pay.paymentFor.id] += pay.amount
      }
    }

    for (const id in orderPmtsTotal) {
      //Total on payments is greater than total on order
      if (orderPmtsTotal[id] > orderTotalMap[id]) {
        overPaymentMap[id] = (orderPmtsTotal[id] - orderTotalMap[id]).toFixed(2)
      }
    }

    return overPaymentMap
  }

  checkInvoiceTxnStatuses = async (invoice) => {
    const nonFinalTxnIds = []
    invoice.transactions.forEach((txn) => {
      if (txn.amount > 0 && txn.status != SETTLED) {
        nonFinalTxnIds.push(txn.id)
      }
    })
    let statusMap = {}
    const updatedTransactions = []
    const refundableTxns = []
    const voidableTxns = []
    if (nonFinalTxnIds.length > 0) {
      statusMap = await this.props.checkInvoiceTxnStatuses(nonFinalTxnIds)
    }

    invoice.transactions.forEach((txn) => {
      if (statusMap[txn.id] && statusMap[txn.id] !== txn.status) {
        txn.status = statusMap[txn.id]
        updatedTransactions.push(txn)
      }
      if (
        txn.amount > 0 &&
        txn.status == SETTLED &&
        !invoice.transactions.some(
          (transaction) =>
            transaction.refundTransactionId === txn.id &&
            Math.abs(transaction.amount) === txn.amount,
        )
      ) {
        refundableTxns.push(txn)
      } else if (txn.amount > 0 && txn.status != SETTLED) {
        voidableTxns.push(txn)
      }
    })
    this.setState({
      invoice,
      updatedTransactions,
      refundableTxns,
      voidableTxns,
    })
  }

  searchOrders = async () => {
    const { searchOrders, searchOrderPageCount } = this.props
    const {
      from,
      invoicedOrders,
      to,
      search,
      selectedClient,
      searchPage,
      searchLimit,
      orderTypes: orderTypesStr,
    } = this.state

    const orderTypes = []
    const cateringTypes = []
    orderTypesStr.forEach((str) => {
      const strs = str.split('.')
      orderTypes.push(strs[0])
      if (strs.length > 1) {
        cateringTypes.push(strs[1])
      }
    })

    this.setState({ allOrdersLoading: true })
    const orders = await searchOrders({
      from,
      to,
      search,
      clientId: selectedClient ? selectedClient.id : '',
      orderTypes,
      cateringTypes,
      isPaid: false,
      page: searchPage,
      limit: searchLimit,
      excludedIds: invoicedOrders.map((order) => order.id),
    })

    const pageCount = await searchOrderPageCount({
      from,
      to,
      search,
      clientId: selectedClient ? selectedClient.id : '',
      orderTypes,
      cateringTypes,
      isPaid: false,
      page: searchPage,
      limit: searchLimit,
      excludedIds: invoicedOrders.map((order) => order.id),
    })
    const newState = { allOrdersLoading: false }
    if (orders) {
      newState.orders = orders
    }
    if (pageCount || pageCount === 0) {
      newState.pageCount = pageCount
    }
    this.setState(newState)
  }

  loadClient = async (buyerId) => {
    const client = await this.props.getClient(buyerId)
    if (client) {
      const selectedAccountingClient =
        await this.props.getAccountingClient(buyerId)
      this.setState({
        selectedClient: client,
        selectedAccountingClient,
        rootClient: client,
      })
    }
  }

  initARClient = async (buyerId, preloadOrders) => {
    const client = await this.props.getClient(buyerId)
    if (!client) {
      return //error handling of some kind to tell user unable to find AR client
    }
    const selectedAccountingClient =
      await this.props.getAccountingClient(buyerId)
    const contact = client.contacts && client.contacts[0]
    client.email = contact.email
    client.phoneNumber = contact.phone
    client.contactName = contact.name
    const { from, to, search, searchPage, invoice } = this.state
    const params = preloadOrders
      ? {
          clientId: buyerId,
          orderIds: preloadOrders,
          isPaid: false,
          page: 0,
          limit: 0,
        }
      : {
          clientId: buyerId,
          isPaid: false,
          from,
          to,
          search,
          page: searchPage,
          limit: 0,
        }

    const orders = await this.props.searchOrders(params)
    if (orders && orders.length > 0) {
      this.setState(
        {
          isARInvoice: true,
          selectedClient: client,
          selectedAccountingClient,
          rootClient: client,
          contactEmail: contact.email,
          contactPhone: contact.phone,
          contactName: contact.name,
          invoice: {
            ...invoice,
            buyer: client,
            contactEmail: contact.email,
            contactPhone: contact.phone,
            contactName: contact.name,
          },
        },
        () => {
          const { displayWarningMessage, newOrderPayment } = this.props
          const { invoice, payments, removedPayments } = this.state
          const addedOrders = []
          const newPayments = []
          let totalInvTip = 0.0
          let totalInvDue = 0.0
          let totalInvDiscount = 0.0

          orders.forEach((order) => {
            if (!order.orderPayments || order.orderPayments.length <= 0) {
              const orderType = getOrderType(order)
              const percent = calcPercentage(order, orderType)

              const {
                tax: orderTax,
                discount: orderDiscount,
                serviceFee: orderServiceFee,
                tip: orderTip,
                total,
              } = getOrderBals(order, percent)

              const paymentAmount = getOrderDefaultInvAmt(
                order,
                payments,
                removedPayments,
                displayWarningMessage,
              )
              const { tip, tax, discount, serviceFee } =
                calculatePaymentAmounts({
                  paymentAmount,
                  total,
                  tip: orderTip,
                  tax: orderTax,
                  discount: orderDiscount,
                  serviceFee: orderServiceFee,
                })
              const payment = newOrderPayment({
                amount: paymentAmount,
                tax,
                discount,
                serviceFee,
                tip,
                paymentForId: order.id,
                paymentForOrderNum: order.orderNumber,
                isInvoiced: order.invoicedAmount + paymentAmount >= total,
              })

              totalInvTip += parseFloat((invoice.tip + tip).toFixed(2))
              totalInvDue += parseFloat(
                (invoice.totalDue + paymentAmount).toFixed(2),
              )
              totalInvDiscount += parseFloat(
                (invoice.discountAmount + discount).toFixed(2),
              )
              addedOrders.push(order)
              newPayments.push(payment)
            }
          })

          this.setState({
            invoicedOrders: addedOrders,
            invoice: {
              ...invoice,
              totalDue: totalInvDue,
              tip: totalInvTip,
              discountAmount: totalInvDiscount,
            },
            payments: newPayments,
          })
        },
      )
    }
  }

  flashMessageCallback = (invoice) => {
    const { uri } = this.props
    window.open(`${uri}/invoices/?invoice_id=${invoice.id}`)
  }

  calcInvoiceBalances = (invoice, payments, removedPayments) => {
    const removedPmtSet = removedPayments.reduce((acc, pmt) => {
      if (!acc.has(pmt.id)) {
        acc.add(pmt.id)
      }

      return acc
    }, new Set())

    const balances = payments.reduce(
      (acc, pmt) => {
        if (!pmt.isPaid && !removedPmtSet.has(pmt.id)) {
          acc.totalDue += pmt.amount
          acc.tip += pmt.tip
          acc.discountAmount += pmt.discount
        } else if (pmt.isPaid && !removedPmtSet.has(pmt.id)) {
          acc.totalPaid += pmt.amount
          acc.tip += pmt.tip
          acc.discountAmount += pmt.discount
        }

        return acc
      },
      { totalDue: 0, totalPaid: 0, tip: 0, discountAmount: 0 },
    )

    invoice.totalDue = balances.totalDue
    invoice.totalPaid = balances.totalPaid
    invoice.tip = balances.tip
    invoice.discountAmount = balances.discountAmount
    if (balances.totalDue > 0) {
      invoice.isPaid = false
    }

    return invoice
  }

  onUpdateEffectiveDateOverride = (date) => {
    const { invoice } = this.state
    invoice.effectiveDateOverride = date.set({ hour: 12 })
    this.setState({ invoice })
  }

  onSaveInvoice = async () => {
    const {
      saveInvoice,
      searchInvoices,
      hideModal,
      displayWarningMessage,
      updateInvoices,
    } = this.props
    const {
      invoice,
      invoicedOrders,
      removedPayments,
      rootClient,
      updatedTransactions,
    } = this.state
    if (this.saving) {
      return
    }

    const payments = this.checkForOrdersWithoutPmts()

    // validate not over invoicing
    if (!invoice.isResolved) {
      const errMessage = validateOverInvoicing(
        payments,
        removedPayments,
        invoicedOrders,
      )
      if (errMessage) {
        displayWarningMessage(errMessage)
        this.setState({ isLoading: false })

        return
      }
    }

    this.saving = true
    invoice.dueDate = calculateDueDate(rootClient, invoice)
    const savedInvoice = await saveInvoice({
      invoice,
      payments,
      removedPayments,
      updatedTransactions,
      callbackFn: this.flashMessageCallback,
    })
    if (savedInvoice) {
      hideModal()
      updateInvoices(savedInvoice)
      searchInvoices && searchInvoices()
    } else {
      this.setState({ isLoading: false })
    }
    this.saving = false
  }

  onSelectClient = async (client, clientLocked = false) => {
    const newState = { selectedClient: client, searchPage: 0, orders: [] }
    if (!clientLocked) {
      newState.rootClient = client
    }
    const accountingClient = await this.props.getAccountingClient(client.id)
    if (accountingClient) {
      newState.selectedAccountingClient = accountingClient
    }
    this.setState(newState, this.searchOrders)
  }

  onSelectContact = (contact) => {
    const { newBuyer } = this.props
    const { invoice, selectedClient } = this.state

    const buyer = newBuyer({
      id: selectedClient.id,
      name: selectedClient.name,
      email: contact.email,
      phoneNumber: contact.phone,
      contactName: contact.name,
      pin: selectedClient.pin,
    })

    this.setState({
      invoice: {
        ...invoice,
        buyer,
        contactEmail: contact.email,
        contactPhone: contact.phone,
        contactName: contact.name,
      },
    })
  }

  onSelectAddress = (address) => {
    const { invoice } = this.state
    const { line1, line2, city, state, zip } = address

    this.setState({
      invoice: {
        ...invoice,
        billingLine1: line1,
        billingLine2: line2,
        billingCity: city,
        billingState: state,
        billingZip: zip,
      },
      editingBilling: false,
      fullBillingAddress: address.fullAddress,
    })
  }

  onSelectAllOrders = async () => {
    const { displayWarningMessage, newOrderPayment, searchOrders } = this.props
    const {
      invoice,
      from,
      to,
      search,
      selectedClient,
      searchPage,
      searchLimit,
      orderTypes: orderTypesStr,
      removedPayments,
      invoicedOrders,
      payments,
    } = this.state
    const newRemovedPayments = [...removedPayments]

    const orderTypes = []
    const cateringTypes = []
    orderTypesStr.forEach((str) => {
      const strs = str.split('.')
      orderTypes.push(strs[0])
      if (strs.length > 1) {
        cateringTypes.push(strs[1])
      }
    })

    let finished = false
    let page = searchPage
    const newOrderPmts = []
    const newInvoiceOrders = []
    this.setState({ allOrdersLoading: true })
    while (!finished) {
      const orders = await searchOrders({
        from,
        to,
        search,
        clientId: selectedClient ? selectedClient.id : '',
        orderTypes,
        cateringTypes,
        isPaid: false,
        page: page,
        limit: searchLimit,
        excludedIds: invoicedOrders.map((order) => order.id),
      })
      if (orders) {
        orders.forEach((order) => {
          const orderType = getOrderType(order)
          const percent = calcPercentage(order, orderType)
          const {
            tax: orderTax,
            discount: orderDiscount,
            serviceFee: orderServiceFee,
            tip: orderTip,
            total,
          } = getOrderBals(order, percent)
          const paymentAmount = getOrderDefaultInvAmt(
            order,
            payments,
            removedPayments,
            displayWarningMessage,
          )
          const { tip, tax, discount, serviceFee } = calculatePaymentAmounts({
            paymentAmount,
            total,
            tip: orderTip,
            tax: orderTax,
            discount: orderDiscount,
            serviceFee: orderServiceFee,
          })
          const payment = newOrderPayment({
            id: '',
            amount: paymentAmount,
            tax,
            discount,
            serviceFee,
            tip,
            paymentForId: order.id,
            paymentForOrderNum: order.orderNumber,
            isInvoiced: order.invoicedAmount + paymentAmount >= total,
          })
          newOrderPmts.push(payment)
        })
        newInvoiceOrders.push(...orders)
        if (orders.length === searchLimit) {
          page += 1
        } else {
          finished = true
        }
      } else {
        this.setState({ allOrdersLoading: false })

        return
      }
    }
    const inv = this.calcInvoiceBalances(
      invoice,
      [...payments, ...newOrderPmts],
      newRemovedPayments,
    )
    const overPaymentMap = this.checkForOverPayments([
      ...payments,
      ...newOrderPmts,
    ])
    this.setState({
      invoice: inv,
      invoicedOrders: [...invoicedOrders, ...newInvoiceOrders],
      payments: [...payments, ...newOrderPmts],
      orders: [],
      pageCount: 0,
      overPaymentMap,
      allOrdersLoading: false,
    })
  }

  onChangePONumber = (e) => {
    const { invoice } = this.state
    const input = e.target.value
    this.setState({
      invoice: {
        ...invoice,
        poNumber: input,
      },
    })
  }

  onSelectIsFinal = () => {
    const { invoice, markFinalReason } = this.state
    const { displayWarningMessage } = this.props
    if (markFinalReason) {
      displayWarningMessage(
        `Cannot mark invoice as final because ${markFinalReason}`,
      )

      return
    } else {
      const inv = { ...invoice, isFinal: !invoice.isFinal }
      this.setState({ invoice: inv })
    }
  }

  onSelectIsResolved = () => {
    const { invoice, initialResolvedByEmail } = this.state
    if (!this.checkIsResolvable()) {
      const hasTxs = invoice.transactions?.length > 0
      if (hasTxs) {
        this.props.displayWarningMessage(
          'Cannot resolve batch invoices with any paid transactions',
        )
      } else {
        this.props.displayWarningMessage(
          'Only finance and master admin roles can resolve batch invoices',
        )
      }

      return
    }
    const { user } = this.props
    const { isResolved } = invoice
    const nextResolvedState = !isResolved
    const resolveStateChanged = initialResolvedByEmail || nextResolvedState
    const updatedInvoice = {
      ...invoice,
      isResolved: nextResolvedState,
      resolvedById: resolveStateChanged ? user.id : null,
      resolvedByEmail: resolveStateChanged ? user.email : null,
      resolvedAt: resolveStateChanged ? Moment() : null,
    }
    this.setState({ invoice: updatedInvoice })
  }

  onAddOrder = (accOrder) => {
    const { displayWarningMessage, newOrderPayment } = this.props
    const { invoice, payments, removedPayments, invoicedOrders, orders } =
      this.state
    if (invoice.isPaid) {
      displayWarningMessage(
        'Unable to add orders onto an already paid invoice.',
      )

      return
    }

    const orderType = getOrderType(accOrder)
    const percent = calcPercentage(accOrder, orderType)
    const {
      tax: orderTax,
      discount: orderDiscount,
      serviceFee: orderServiceFee,
      tip: orderTip,
      total,
    } = getOrderBals(accOrder, percent)
    let existingId
    const idx = removedPayments.findIndex(
      (rPay) => rPay.paymentForId === accOrder.id,
    )
    // when re-adding deleted existing order payment
    if (idx > -1) {
      existingId = removedPayments[idx].id
    }
    const paymentAmount = getOrderDefaultInvAmt(
      accOrder,
      payments,
      removedPayments,
      displayWarningMessage,
    )

    const { tip, tax, discount, serviceFee } = calculatePaymentAmounts({
      paymentAmount,
      total,
      tip: orderTip,
      tax: orderTax,
      discount: orderDiscount,
      serviceFee: orderServiceFee,
    })
    const payment = newOrderPayment({
      id: existingId,
      amount: paymentAmount,
      tax,
      discount,
      serviceFee,
      tip,
      paymentForId: accOrder.id,
      paymentForOrderNum: accOrder.orderNumber,
      isInvoiced: accOrder.invoicedAmount + paymentAmount >= total,
    })
    const newOrders = orders.filter((order) => order.id !== accOrder.id)

    const invoiceTip = parseFloat((invoice.tip + tip).toFixed(2))
    const totalDue = parseFloat((invoice.totalDue + paymentAmount).toFixed(2))
    const discountAmount = parseFloat(
      (invoice.discountAmount + discount).toFixed(2),
    )

    this.setState({
      invoicedOrders: [...invoicedOrders, accOrder],
      invoice: { ...invoice, totalDue, tip: invoiceTip, discountAmount },
      payments: [...payments, payment],
      removedPayments:
        idx > -1
          ? [
              ...removedPayments.slice(0, idx),
              ...removedPayments.slice(idx + 1),
            ]
          : removedPayments,
      orders: newOrders,
    })
  }

  onDeleteOrder = (orderId) => {
    const { invoice, payments, removedPayments, invoicedOrders, orders } =
      this.state
    const { displayWarningMessage, newRemovedPayment } = this.props
    const newPayments = payments.filter((pmt) => pmt.paymentFor.id !== orderId)
    const newRemovedPayments = [...removedPayments]
    payments.forEach((pmt) => {
      if (pmt.paymentFor.id === orderId && pmt.id) {
        //handles newly added payments that are not persisted
        newRemovedPayments.push(
          newRemovedPayment({ id: pmt.id, paymentForId: orderId }),
        )
      }
    })

    const paidFromInvoice = checkIfPaidFromInvoice(payments, orderId)
    if (paidFromInvoice) {
      displayWarningMessage(
        'Cannot remove an order from a batch invoice that has a paid payment on the invoice against the order.',
      )

      return
    }

    const invoicedAmt = payments
      .filter((pmt) => pmt.paymentFor.id === orderId)
      .reduce((acc, pmt) => (acc += pmt.amount), 0)
    const order = invoicedOrders.find((order) => order.id === orderId)
    order.invoicedAmount = order.invoicedAmount - invoicedAmt
    const newInvoiceOrders = invoicedOrders.filter(
      (order) => order.id !== orderId,
    )
    const inv = this.calcInvoiceBalances(
      invoice,
      newPayments,
      newRemovedPayments,
    )

    this.setState({
      invoice: inv,
      invoicedOrders: newInvoiceOrders,
      orders: [...orders, order],
      payments: newPayments,
      removedPayments: newRemovedPayments,
    })
  }

  onDeletePayment = (index) => {
    const { invoice, payments, removedPayments } = this.state
    const { newRemovedPayment } = this.props
    const unpaidPmts = payments.filter((pmt) => !pmt.isPaid)
    const payment = unpaidPmts[index]
    const newRemovedPayments = [...removedPayments]
    const newUnpaid = unpaidPmts
      .slice(0, index)
      .concat(unpaidPmts.slice(index + 1))
    const newPayments = payments.filter((pmt) => pmt.isPaid).concat(newUnpaid)
    if (payment.id) {
      //No need to remove unsaved payment
      newRemovedPayments.push(
        newRemovedPayment({
          id: payment.id,
          paymentForId: payment.paymentFor.id,
        }),
      )
    }
    const inv = this.calcInvoiceBalances(
      invoice,
      newPayments,
      newRemovedPayments,
    )

    this.setState({
      invoice: inv,
      payments: newPayments,
      removedPayments: newRemovedPayments,
    })
  }

  getOrderNum = async ({ id }) => {
    const order = await this.props.getOrder(id)

    return order.number
  }

  searchAfterTimeout = () => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer)
    }
    this.searchTimer = undefined
    this.searchTimer = setTimeout(() => {
      this.searchOrders()
    }, 550)
  }

  onChangeOrderType = (type) => {
    let newFilters = [...this.state.orderTypes]
    const checkedValues = newFilters || []
    const index = checkedValues.findIndex((value) => value === type.value)
    if (index !== -1) {
      checkedValues.splice(index, 1)
    } else {
      checkedValues.push(type.value)
    }
    newFilters = checkedValues
    this.setState({ orderTypes: newFilters, searchPage: 0 }, this.searchOrders)
  }

  onChangeModal = (modal) => {
    const { payments } = this.state
    let customPayAmount = 0
    if (modal === 'showCustomPayModal') {
      customPayAmount = payments
        .filter((pmt) => !pmt.isPaid)
        .reduce((acc, pmt) => (acc += pmt.amount), 0)
    }
    this.setState({ [modal]: !this.state[modal], customPayAmount })
  }

  unpaidOrderTitle = (order) => {
    const orderType = getOrderType(order)
    const { total } = getOrderBals(order)
    const totalPaid = calcOrderTotalPaid(order)
    const totalUnpaid = total - totalPaid
    const totalPaidOrInvAmount = calcOrderTotalPaidOrBatchedAmt(order)
    const totalUnpaidBatched = calcOrderTotalUnpaidBatchedAmt(order)
    const pctPaidOrInv = calcPercentPaidOrInvoiced(total, totalPaidOrInvAmount)
    const headCount =
      orderType === 'Group Order' || orderType === 'Pop Up'
        ? order.numOrders
        : order.headCount

    return (
      <tr key={order.id} onClick={() => this.onAddOrder(order)}>
        <td> {order.orderNumber} </td>
        <td> {Moment(order.date).format('MM/DD/YYYY')} </td>
        <td> {Moment(order.effectiveDate).format('MM/DD/YYYY')} </td>
        <td> {Moment(order.dueDate).format('MM/DD/YYYY')} </td>
        <td> {headCount} </td>
        <td> ${total.toFixed(2)} </td>
        <td> ${totalPaid.toFixed(2)} </td>
        <td> ${totalUnpaid.toFixed(2)} </td>
        <td> ${totalUnpaidBatched.toFixed(2)} </td>
        <td> {pctPaidOrInv}% </td>
      </tr>
    )
  }

  renderOrder = (order) => {
    const { payments, removedPayments } = this.state
    if (!payments) {
      return <div></div>
    }
    const orderType = getOrderType(order)
    const { subtotal, tax, discount, serviceFee, tip, total } =
      getOrderBals(order)

    const totalPaid = calcOrderTotalPaid(order, removedPayments, payments)
    const totalUnpaidBatched = calcOrderTotalUnpaidBatchedAmt(
      order,
      removedPayments,
      payments,
    )
    const paidOrInvAmount = calcOrderTotalPaidOrBatchedAmt(
      order,
      removedPayments,
      payments,
    )
    const pctPaidOrInv = calcPercentPaidOrInvoiced(total, paidOrInvAmount)
    const remaining =
      total - paidOrInvAmount ? roundAmt(total - paidOrInvAmount) : 0
    const percentRemaining = 100 - pctPaidOrInv
    let backgroundColor = '#FFFF00' // yellow
    let inputColor
    if (pctPaidOrInv === 100) {
      backgroundColor = '#8B0000' // green
      inputColor = '#FFFFFF'
    } else if (pctPaidOrInv > 100 || pctPaidOrInv < 0) {
      backgroundColor = '#8B0000' // red
      inputColor = '#FFFFFF'
    }
    const invoiceNumbers = getOrderBatchInvs(order)

    return (
      <tr key={order.id}>
        <td>{order.orderNumber}</td>
        <td>{invoiceNumbers.join(', ')}</td>
        <td>{Moment(order.date).format('MM/DD/YYYY')}</td>
        <td>{Moment(order.effectiveDate).format('MM/DD/YYYY')}</td>
        <td>{Moment(order.dueDate).format('MM/DD/YYYY')}</td>
        <td>{orderType === 'Catering' ? order.headCount : order.numOrders}</td>
        <td>${subtotal.toFixed(2)}</td>
        <td>${discount.toFixed(2)}</td>
        <td>${serviceFee.toFixed(2)}</td>
        <td>${tax.toFixed(2)}</td>
        <td>${tip.toFixed(2)}</td>
        <td>${total.toFixed(2)}</td>
        <td>${totalPaid.toFixed(2)}</td>
        <td>${totalUnpaidBatched.toFixed(2)}</td>
        <td>
          <FlexContainer flexDirection="row">
            <Input
              background={backgroundColor}
              color={inputColor}
              width="40px"
              marginBottom="0"
              type="number"
              textAlign="center"
              value={pctPaidOrInv}
              disabled={true}
            />
          </FlexContainer>
        </td>
        <td>
          ${remaining.toFixed(2)} ({percentRemaining}%)
        </td>
        <td style={{ minWidth: '38px' }}>
          <img
            onClick={() => {
              if (!this.checkIsEditable()) {
                this.props.displayWarningMessage(
                  'Cannot edit invoice balance after saving',
                )

                return
              }
              this.onDeleteOrder(order.id)
            }}
            className="table-trash"
            src={trash}
          />
        </td>
      </tr>
    )
  }

  renderOrdersTotalRow = (invoicedOrders) => {
    const { payments, removedPayments } = this.state
    const balances = invoicedOrders.reduce(
      (acc, order) => {
        const { subtotal, tax, discount, serviceFee, tip, total } =
          getOrderBals(order)
        const totalUnpaidBatched = calcOrderTotalUnpaidBatchedAmt(
          order,
          removedPayments,
          payments,
        )
        const totalPaid = calcOrderTotalPaid(order, removedPayments, payments)
        const invAmt = calcOrderTotalPaidOrBatchedAmt(
          order,
          removedPayments,
          payments,
        )
        acc.subtotal += subtotal
        acc.tax += tax
        acc.discount += discount
        acc.serviceFee += serviceFee
        acc.tip += tip
        acc.total += total
        acc.totalPaid += totalPaid
        acc.invoicedAmount += invAmt
        acc.totalUnpaidBatched += totalUnpaidBatched

        return acc
      },
      {
        subtotal: 0,
        tax: 0,
        discount: 0,
        serviceFee: 0,
        tip: 0,
        total: 0,
        totalPaid: 0,
        invoicedAmount: 0,
        totalUnpaidBatched: 0,
      },
    )

    const { total, invoicedAmount } = balances
    const ttlRemaining = !total ? 0 : total - invoicedAmount
    const ttlPercent = !ttlRemaining
      ? 100
      : Math.round((invoicedAmount / total) * 100)

    return (
      <TotalRow>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <p>
            <strong>TOTAL:</strong>
          </p>
        </td>
        <td>
          <p>${balances.subtotal.toFixed(2)}</p>
        </td>
        <td>
          <p>${balances.discount.toFixed(2)}</p>
        </td>
        <td>
          <p>${balances.serviceFee.toFixed(2)}</p>
        </td>
        <td>
          <p>${balances.tax.toFixed(2)}</p>
        </td>
        <td>
          <p>${balances.tip.toFixed(2)}</p>
        </td>
        <td>
          <p>${balances.total.toFixed(2)}</p>
        </td>
        <td>
          <p>${balances.totalPaid.toFixed(2)}</p>
        </td>
        <td>
          <p>${balances.totalUnpaidBatched.toFixed(2)}</p>
        </td>
        <td />
        <td>
          <p>
            ${ttlRemaining.toFixed(2)} ({(100 - ttlPercent).toFixed(2)}%)
          </p>
        </td>
      </TotalRow>
    )
  }

  onChange = (field) => (e) => {
    let value = e
    if (value.target) {
      ;({ value } = value.target)
    }
    this.setState({ [field]: value })
  }

  onChangeAmount = (index, value) => {
    const { invoice, payments, invoicedOrders, removedPayments } = this.state
    const unpaidPmts = payments.filter((pmt) => !pmt.isPaid)
    const payment = unpaidPmts[index]
    payment.amount = value
    const orderId = payment.paymentFor.id
    if (orderId) {
      const order = invoicedOrders.find((o) => o.id === orderId)
      const { total, tax, tip, discount, serviceFee } = getOrderBals(order)
      const paidBalances = (order.orderPayments || []).reduce(
        (acc, pmt) => {
          if (pmt.isPaid) {
            acc.total += pmt.amount
            acc.tip += pmt.tip
            acc.tax += pmt.tax
            acc.serviceFee += pmt.serviceFee
            acc.discount += pmt.discount
          }

          return acc
        },
        { total: 0, tip: 0, tax: 0, serviceFee: 0, discount: 0 },
      )
      const unpaidTotal = total - paidBalances.total
      const pct = unpaidTotal ? value / unpaidTotal : 0
      payment.tip = Number(((tip - paidBalances.tip) * pct).toFixed(2))
      payment.tax = Number(((tax - paidBalances.tax) * pct).toFixed(2))
      payment.discount = Number(
        ((discount - paidBalances.discount) * pct).toFixed(2),
      )
      payment.serviceFee = Number(
        ((serviceFee - paidBalances.serviceFee) * pct).toFixed(2),
      )
    }

    const inv = this.calcInvoiceBalances(invoice, payments, removedPayments)
    this.setState({
      invoice: inv,
      payments,
    })
  }

  onChangeOrderNum = (index, orderNumber) => {
    const { invoice, invoicedOrders, payments, removedPayments } = this.state
    const accOrder = invoicedOrders.find((o) => o.orderNumber === orderNumber)
    const paidBalances = accOrder.orderPayments.reduce(
      (acc, pmt) => {
        if (pmt.isPaid) {
          acc.total += pmt.amount
          acc.tip += pmt.tip
          acc.tax += pmt.tax
          acc.serviceFee += pmt.serviceFee
          acc.discount += pmt.discount
        }

        return acc
      },
      { total: 0, tip: 0, tax: 0, serviceFee: 0, discount: 0 },
    )
    const unpaidPmts = payments.filter((pmt) => !pmt.isPaid)
    const payment = unpaidPmts[index]
    payment.paymentFor = accOrder
    if (payment.amount) {
      const { total, tax, tip, discount, serviceFee } = getOrderBals(accOrder)
      const unpaidTotal = total - paidBalances.total
      const pct = unpaidTotal ? payment.amount / unpaidTotal : 0
      payment.tip = Number(((tip - paidBalances.tip) * pct).toFixed(2))
      payment.tax = Number(((tax - paidBalances.tax) * pct).toFixed(2))
      payment.discount = Number(
        ((discount - paidBalances.discount) * pct).toFixed(2),
      )
      payment.serviceFee = Number(
        ((serviceFee - paidBalances.serviceFee) * pct).toFixed(2),
      )
    }

    const inv = this.calcInvoiceBalances(invoice, payments, removedPayments)

    this.setState({
      invoice: inv,
      payments: payments,
    })
  }

  handleAdHocPayment = () => {
    const { payments } = this.state
    const { newOrderPayment } = this.props
    const adhocPmt = newOrderPayment({
      id: undefined,
      amount: 0,
      tax: 0,
      tip: 0,
      discount: 0,
      serviceFee: 0,
    })
    payments.push(adhocPmt)
    this.setState({
      payments: payments,
    })
  }

  onSelectPaymentMethod = (e) => {
    const paymentMethodId = e.target.value
    const { paymentMethods } = this.state.rootClient
    if (paymentMethodId === ACH_CHECK) {
      this.setState({
        paymentMethodId: ACH_CHECK,
        customPaymentMethod: ACH_CHECK,
      })
    } else {
      const paymentMethod = paymentMethods.find(
        (pmt) => pmt.id === paymentMethodId,
      )
      if (!paymentMethod.billingAddressId) {
        this.props.displayWarningMessage(
          `Payment ending with ${paymentMethod.last4} does not have a billing address, please add one before processing payment`,
        )

        return
      }

      this.setState({ paymentMethodId, customPaymentMethod: paymentMethod })
    }
  }

  updateInvModal = ({
    invoice,
    payments,
    removedPayments,
    showCustomPayModal,
  }) => {
    this.setState({ invoice, payments, removedPayments, showCustomPayModal })
  }

  checkForOrdersWithoutPmts = () => {
    const { payments, invoicedOrders } = this.state
    const { newOrderPayment } = this.props
    for (const order of invoicedOrders) {
      if (!payments.some((pmt) => pmt.paymentFor.id === order.id)) {
        //ensures all orders on invoice has atleast one payment
        payments.push(
          newOrderPayment({
            id: undefined,
            amount: 0,
            tax: 0,
            discount: 0,
            serviceFee: 0,
            tip: 0,
            paymentForId: order.id,
            paymentForDate: order.date,
            paymentForOrderNum: order.orderNumber,
          }),
        )
      }
    }

    return payments
  }

  processUnpaidPayments = async () => {
    const {
      customPaymentMethod,
      invoice,
      removedPayments,
      updatedTransactions,
    } = this.state
    const { displayWarningMessage, updateInvoices, payInvoice, saveInvoice } =
      this.props
    const { isResolved } = invoice

    if (isResolved) {
      displayWarningMessage('This Invoice has been resolved')
      this.setState({ isLoading: false })

      return
    }

    const pay = await this.props.confirmationModal.show({
      text: 'Are you sure you want to pay this invoice with the payment method selected?',
    })

    if (!pay) {
      this.setState({ isLoading: false })

      return
    }

    const payments = this.checkForOrdersWithoutPmts()
    const customPayAmount = payments
      .filter((pmt) => !pmt.isPaid)
      .reduce((acc, pmt) => (acc += pmt.amount), 0)

    invoice.totalDue = customPayAmount
    invoice.isPaid = false

    const savedInvoice = await saveInvoice(
      { invoice, payments, removedPayments, updatedTransactions },
      false,
      false,
    )
    if (!savedInvoice) {
      this.setState({ isLoading: false })

      return
    }

    // on staging dgraph is on a shared instance and drops txs if the same node is being updated
    // need to delay payment request as it updates the same invoice node in quick succession
    const submitPayInvoiceRequest = async () => {
      const payInvoiceReq = {
        invoiceId: savedInvoice.id,
        tip: savedInvoice.tip,
        customPayAmount, //used in displaySuccessMssg
      }
      if (customPaymentMethod === ACH_CHECK) {
        payInvoiceReq.paymentMethod = {
          achCheck: true,
        }
      } else {
        ;(payInvoiceReq.nonce = customPaymentMethod.nonce),
          (payInvoiceReq.cardholderName = customPaymentMethod.cardholderName),
          (payInvoiceReq.billingAddress = customPaymentMethod.billingAddress),
          (payInvoiceReq.paymentMethod = {
            token: customPaymentMethod.token,
            cardholderName: customPaymentMethod.cardholderName,
            cardType: customPaymentMethod.cardType,
            expirationDate: customPaymentMethod.expirationDate,
            gateway: customPaymentMethod.gateway,
            last4: customPaymentMethod.last4,
          })
      }

      const inv = await payInvoice(payInvoiceReq)

      if (inv) {
        updateInvoices(inv)
      } else {
        this.setState({ isLoading: false })
      }
    }
    setTimeout(submitPayInvoiceRequest, 3300)
  }

  renderTxnTable = () => {
    const { invoice, collapseTxnPaymentsMap } = this.state
    const { transactions } = invoice

    return (
      <Table
        headings={[
          'Pay/Refund/Void',
          'Date',
          'Transaction Id',
          'Transaction Amount',
          'Status',
          'Voided/Refunded By',
          'See Payments',
        ]}
      >
        {transactions.map((txn) => {
          let payVoidOrRefund = 'Payment'
          if (txn.amount === 0) {
            payVoidOrRefund = 'Void'
          } else if (txn.amount < 0) {
            payVoidOrRefund = 'Refund'
          }
          const showPayments = collapseTxnPaymentsMap[txn.id]
          if (showPayments) {
            return (
              <React.Fragment key={txn.id}>
                <tr key={txn.id}>
                  <td>{payVoidOrRefund}</td>
                  <td>{Moment(txn.createdAt).format('MM/DD/YYYY')}</td>
                  <td>{txn.id}</td>
                  <td>{txn.amount.toFixed(2)}</td>
                  <td>{txn.status}</td>
                  <td>{txn.amount <= 0 ? txn.processedBy : 'N/A'}</td>
                  <td
                    onClick={() =>
                      this.setState({
                        collapseTxnPaymentsMap: {
                          ...collapseTxnPaymentsMap,
                          [txn.id]: !collapseTxnPaymentsMap[txn.id],
                        },
                      })
                    }
                  >
                    <IconContext.Provider value={{ size: '25px' }}>
                      <div>
                        <BsArrowsExpand />
                      </div>
                    </IconContext.Provider>
                  </td>
                </tr>
                {txn.payments.map((pmt) => (
                  <tr key={pmt.id}>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {payVoidOrRefund} For:
                    </td>
                    <td>{pmt.paymentFor.orderNumber}</td>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      Total on payment:
                    </td>
                    <td>{pmt.amount.toFixed(2)}</td>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      Date:{' '}
                    </td>
                    <td>{Moment(pmt.updatedAt).format('MM/DD/YYYY')}</td>
                    <td></td>
                  </tr>
                ))}
              </React.Fragment>
            )
          } else {
            return (
              <tr key={txn.id}>
                <td>{payVoidOrRefund}</td>
                <td>{Moment(txn.createdAt).format('MM/DD/YYYY')}</td>
                <td>{txn.id}</td>
                <td>{txn.amount.toFixed(2)}</td>
                <td>{txn.status}</td>
                <td>{txn.amount <= 0 ? txn.processedBy : 'N/A'}</td>
                {payVoidOrRefund === 'Void' ? (
                  <td></td>
                ) : (
                  <td
                    onClick={() =>
                      this.setState({
                        collapseTxnPaymentsMap: {
                          ...collapseTxnPaymentsMap,
                          [txn.id]: !collapseTxnPaymentsMap[txn.id],
                        },
                      })
                    }
                  >
                    <IconContext.Provider value={{ size: '25px' }}>
                      <div>
                        <BsArrowsExpand />
                      </div>
                    </IconContext.Provider>
                  </td>
                )}
              </tr>
            )
          }
        })}
      </Table>
    )
  }

  renderTableHeadings = () => {
    const headings = [
      'Order Num',
      'Invoice(s)',
      'Date',
      'Effective Date',
      'Due Date',
      'Headcount',
      'Subotal',
      'Discount',
      'Service Fee',
      'Tax',
      'Tip',
      'Order Total',
      'Total Paid',
      'Total Unpaid Batched',
      '% Accounted',
      'Remaining',
      '',
    ]

    return (
      <tr>
        {headings.map((heading, i) => (
          <th key={i}>{heading}</th>
        ))}
      </tr>
    )
  }

  renderUnpaidPayments = () => {
    const { displayWarningMessage } = this.props
    const { invoicedOrders, payments, removedPayments } = this.state
    const unpaidPayments = payments.filter((pmt) => !pmt.isPaid)
    const headings = [
      'Order Date',
      'Date Added',
      'Order Number',
      'Subtotal',
      'Discount',
      'Service Fee',
      'Tax',
      'Tip',
      'Total Payment Amount',
      'Select Amount',
      'Order % Accounted',
    ]

    const ttlBalances = unpaidPayments.reduce(
      (acc, pmt) => {
        acc.tax += pmt.tax
        acc.tip += pmt.tip
        acc.service += pmt.serviceFee
        acc.total += pmt.amount
        acc.discount += pmt.discount
        acc.subtotal += !pmt.amount
          ? 0
          : pmt.amount - pmt.tax - pmt.tip - pmt.serviceFee + pmt.discount

        return acc
      },
      { tax: 0, tip: 0, service: 0, subtotal: 0, discount: 0, total: 0 },
    )

    return (
      <Table headings={headings}>
        {unpaidPayments.map((pmt, i) => {
          const pmtOrder = invoicedOrders.find(
            (invOrder) => invOrder.id === pmt.paymentFor.id,
          )

          let orderNum = ''
          let orderDate = ''
          let orderStatus = ''
          let pctPaidOrInv = 100
          // can only select orders that do not already have an unpaid payment against this batch inv
          const orderOptions = invoicedOrders
            .filter(
              (invOrder) =>
                !unpaidPayments.some(
                  (unpaidPmt) => unpaidPmt.paymentFor.id === invOrder.id,
                ),
            )
            .map((o) => (
              <option key={o.id} value={o.orderNumber}>
                {o.orderNumber}
              </option>
            ))
          if (pmtOrder) {
            orderNum = pmtOrder.orderNumber
            orderDate = Moment(pmtOrder.date).format('MM/DD/YYYY')
            const paidOrInvAmount = calcOrderTotalPaidOrBatchedAmt(
              pmtOrder,
              removedPayments,
              payments,
            )
            const { total: orderTotal } = getOrderBals(pmtOrder)
            pctPaidOrInv = calcPercentPaidOrInvoiced(
              orderTotal,
              paidOrInvAmount,
            )
            let orderInvStatus = 'Partially Invoiced'
            if (pctPaidOrInv === 100) {
              orderInvStatus = 'Fully Accounted'
            } else if (pctPaidOrInv > 100) {
              orderInvStatus = 'Over Invoiced'
            }
            orderStatus = `${orderInvStatus} (${pctPaidOrInv}%)`
          } else {
            orderOptions.push(<option value="">- Select</option>)
          }

          return (
            <tr key={i}>
              <td>{orderDate}</td>
              <td>{Moment(pmt.createdAt).format('MM/DD/YYYY')}</td>
              <td>
                {pmtOrder ? (
                  pmtOrder.orderNumber
                ) : (
                  <Dropdown
                    onChange={(e) => this.onChangeOrderNum(i, e.target.value)}
                    width="120px"
                    paddingTop="15px"
                    value={orderNum}
                  >
                    {orderOptions}
                  </Dropdown>
                )}
              </td>
              <td>
                $
                {!pmt.amount
                  ? '0.00'
                  : (
                      pmt.amount -
                      pmt.tax -
                      pmt.tip -
                      pmt.serviceFee +
                      pmt.discount
                    ).toFixed(2)}
              </td>
              <td>${pmt.discount.toFixed(2)}</td>
              <td>${pmt.serviceFee.toFixed(2)}</td>
              <td>${pmt.tax.toFixed(2)}</td>
              <td>${pmt.tip.toFixed(2)}</td>
              <td style={{ maxWidth: '200px' }}>
                <CurrencyInput
                  value={pmt.amount}
                  onChange={(value) => this.onChangeAmount(i, value)}
                  width="100px"
                  disabled={!this.checkIsEditable()}
                />
              </td>
              <td>
                {pmtOrder && (
                  <Dropdown
                    width="170px"
                    paddingTop="15px"
                    disabled={!this.checkIsEditable()}
                    onChange={(e) => {
                      const selectedValue = e.target.value
                      if (selectedValue === TotalUnaccountedOption) {
                        const nextValue = getOrderDefaultInvAmt(
                          pmtOrder,
                          payments.filter(
                            (pmt) =>
                              pmt.id ||
                              pmt.isPaid ||
                              (pmtOrder && pmt.paymentFor?.id !== pmtOrder.id),
                          ), // exclude new payments if editing new inv
                          [...removedPayments, pmt], // exclude existing payments if editing inv
                          displayWarningMessage,
                        )
                        this.onChangeAmount(i, nextValue)
                      }
                    }}
                  >
                    <option>-Select-</option>
                    {DefaultPaymentOptions.map((optVal) => (
                      <option key={optVal} value={optVal}>
                        {optVal}
                      </option>
                    ))}
                  </Dropdown>
                )}
              </td>
              <td>
                <div> {orderStatus} </div>
              </td>
              <td style={{ minWidth: '38px' }}>
                <img
                  onClick={() => {
                    if (!this.checkIsEditable()) {
                      displayWarningMessage(
                        'Cannot edit invoice balance after saving',
                      )

                      return
                    }
                    this.onDeletePayment(i)
                  }}
                  className="table-trash"
                  src={trash}
                />
              </td>
            </tr>
          )
        })}
        <TotalRow>
          <td />
          <td />
          <td>
            <p>TOTAL:</p>
          </td>
          <td>
            <p>${ttlBalances.subtotal.toFixed(2)}</p>
          </td>
          <td>
            <p>${ttlBalances.discount.toFixed(2)}</p>
          </td>
          <td>
            <p>${ttlBalances.service.toFixed(2)}</p>
          </td>
          <td>
            <p>${ttlBalances.tax.toFixed(2)}</p>
          </td>
          <td>
            <p>${ttlBalances.tip.toFixed(2)}</p>
          </td>
          <td>
            <p>${ttlBalances.total.toFixed(2)}</p>
          </td>
        </TotalRow>
      </Table>
    )
  }

  renderPaymentMethods = () => {
    const { paymentMethodId, customPaymentMethod, rootClient, isLoading } =
      this.state

    if (!rootClient) {
      return <div></div>
    }

    return (
      <FlexContainer flexDirection="column" alignItems="center">
        <ModalSectionTitle>
          Pay Now With Card On File / Mark Paid
        </ModalSectionTitle>
        <Dropdown
          label="Choose Payment"
          value={paymentMethodId}
          onChange={this.onSelectPaymentMethod}
          width="45%"
        >
          <option>-Select-</option>
          <AuthorizedInteractable
            roles={['master admin', 'finance', 'accounting']}
          >
            <option value={ACH_CHECK}>ACH/Check</option>
          </AuthorizedInteractable>
          {rootClient.paymentMethods &&
            rootClient.paymentMethods.map((a) => (
              <option key={a.id} value={a.id}>
                {a.shortName}
              </option>
            ))}
        </Dropdown>
        {customPaymentMethod &&
          (isLoading ? (
            <LoadingIndicator />
          ) : (
            <Button
              label="Pay all outstanding balances"
              backgroundColor={colors.violet}
              onClick={() =>
                this.setState({ isLoading: true }, this.processUnpaidPayments)
              }
            />
          ))}
      </FlexContainer>
    )
  }

  render() {
    const {
      allOrdersFullyPaid,
      isLoading,
      invoice,
      invoicedOrders,
      payments,
      pageCount,
      orders,
      overPaymentMap,
      selectedClient,
      selectedAccountingClient,
      rootClient,
      to,
      from,
      search,
      searchPage,
      orderTypes,
      showCustomPayModal,
      showRefundModal,
      showVoidModal,
      refundableTxns,
      existingInvoice,
      editingBilling,
      fullBillingAddress,
      customPayAmount,
      voidableTxns,
      markFinalReason,
      wasFinal,
    } = this.state
    const { poNumber, notes, internalInvoiceNotes, transactions } = invoice

    if (!invoice) {
      return
    }
    const {
      id,
      buyer,
      tip,
      totalDue,
      totalPaid,
      contactName,
      contactEmail,
      billingLine1,
      isFinal,
      isResolved,
      resolvedByEmail,
      resolvedAt,
    } = invoice
    const { headquarter, hideModal, searchClients } = this.props

    const title = id ? `Edit Invoice: ${invoice.invoiceNumber}` : 'New Invoice'
    const unpaidPayments = payments.filter((pmt) => !pmt.isPaid)
    const unpaidOrders = orders ? orders : []
    const lockedClient = payments && payments.length > 0
    const { familialAccounts, familialContacts, familialAddresses } =
      rootClient || {}
    const familialAddressesArr =
      familialAddresses && Object.values(familialAddresses)
    const familialAccountsArr =
      familialAccounts && Object.values(familialAccounts)
    const familialContactsArr =
      familialContacts && Object.values(familialContacts)
    const invoiceContact =
      contactEmail &&
      familialContactsArr &&
      familialContactsArr.find((c) => c.email === contactEmail)

    if (showRefundModal) {
      return (
        <InvoiceRefundModal
          overPaymentMap={overPaymentMap}
          invoice={invoice}
          title={title}
          payments={payments}
          refundableTxns={refundableTxns}
          handleRefundModal={this.onChangeModal}
          updateInvoices={this.props.updateInvoices}
        />
      )
    } else if (showVoidModal) {
      return (
        <InvoiceVoidModal
          invoice={invoice}
          title={title}
          payments={payments}
          voidableTxns={voidableTxns}
          handleVoidModal={this.onChangeModal}
          updateInvoices={this.props.updateInvoices}
        />
      )
    } else if (showCustomPayModal) {
      return (
        <InvoiceCustomPayModal
          customAmtFromView={customPayAmount}
          invoice={invoice}
          invoicedOrders={invoicedOrders}
          payments={payments}
          title={title}
          handleCustomPayModal={this.onChangeModal}
          updateInvoices={this.props.updateInvoices}
          flashMessageCallback={this.flashMessageCallback}
          updateInv={this.handleHandleCustomPayments}
          updateInvModal={this.updateInvModal}
        />
      )
    } else {
      return (
        <Modal
          width="1650px"
          title={title}
          hideModal={hideModal}
          color="#001940"
        >
          <OrderFormSectionTitle>
            <span>1</span>Client Details
          </OrderFormSectionTitle>
          <YSpacing height="10px" />
          <div className="flex justify-between mb-5">
            <div className="w-full flex flex-col">
              {lockedClient ? (
                <Dropdown
                  width="48%"
                  label="Client"
                  value={selectedClient && selectedClient.id}
                  onChange={(e) =>
                    this.onSelectClient(
                      familialAccounts[e.target.value],
                      lockedClient,
                    )
                  }
                >
                  <option />
                  {(familialAccountsArr || []).map((act) => (
                    <option key={act.id} value={act.id}>
                      {act.name}
                    </option>
                  ))}
                </Dropdown>
              ) : (
                <AutocompleteInput
                  label="Client"
                  width="48%"
                  displayAttribute="name"
                  loaderFunction={(search) =>
                    searchClients({
                      ...search,
                      headquarter,
                      for_invoices: true,
                    })
                  }
                  placeholder={'Search Clients'}
                  value={
                    (buyer && buyer.name) ||
                    (selectedClient && selectedClient.name)
                  }
                  onSelect={this.onSelectClient}
                />
              )}
              {selectedAccountingClient?.autoBatchActive && (
                <p className="text-red-600">* Auto batch invoice client</p>
              )}
            </div>
            <Dropdown
              width="48%"
              label="Contact"
              value={invoiceContact && invoiceContact.id}
              onChange={(e) =>
                this.onSelectContact(familialContacts[e.target.value])
              }
            >
              <option />
              {(familialContactsArr || []).map((ct) => (
                <option key={ct.id} value={ct.id}>
                  {ct.name}
                </option>
              ))}
            </Dropdown>
          </div>

          <FlexContainer>
            {editingBilling ? (
              <Dropdown
                width="48%"
                label="Billing Address"
                onChange={(e) =>
                  this.onSelectAddress(familialAddresses[e.target.value])
                }
              >
                <option />
                {(familialAddressesArr || []).map((addr) => (
                  <option key={addr.id} value={addr.id}>
                    {addr.fullAddress}
                  </option>
                ))}
              </Dropdown>
            ) : (
              <div>
                <LabelInfo label="Billing Address" value={fullBillingAddress} />
                <YSpacing height="20px" />
                <Button
                  onClick={() => this.setState({ editingBilling: true })}
                  label={
                    billingLine1 && billingLine1 != ''
                      ? 'Change Billing Address'
                      : 'Select Billing Address'
                  }
                />
                <YSpacing height="30px" />
              </div>
            )}
          </FlexContainer>

          <FlexContainer flexDirection="column">
            <div>
              PO Number on the orders are pulled onto the batch invoice
              automatically. This field will override order PO numbers.
            </div>
            <Input
              label="PO Number / Reference"
              marginBottom="0"
              width="200px"
              type="text"
              value={poNumber}
              onChange={(e) =>
                this.setState({
                  invoice: { ...invoice, poNumber: e.target.value },
                })
              }
            />
            <YSpacing height="20px" />
            <div>
              Notes are displayed in the &quot;Additional Notes&quot; section of
              the invoice
            </div>
            <Textarea
              width={'100%'}
              label="Invoice Notes"
              marginBottom={'0px'}
              value={notes}
              onChange={(e) =>
                this.setState({
                  invoice: { ...invoice, notes: e.target.value },
                })
              }
            />
            <YSpacing height="20px" />
            <div>
              Internal notes are not displayed to the client and are used
              internally only.
            </div>
            <Textarea
              width={'100%'}
              label="Internal Invoice Notes"
              marginBottom={'0px'}
              value={internalInvoiceNotes}
              onChange={(e) =>
                this.setState({
                  invoice: { ...invoice, internalInvoiceNotes: e.target.value },
                })
              }
            />
          </FlexContainer>

          <YSpacing height="20px" />

          <DividerLine height="3px" />
          <YSpacing height="15px" />
          <OrderFormSectionTitle>
            <span>2</span>Search Order Conditions
          </OrderFormSectionTitle>
          <YSpacing height="10px" />
          <FlexContainer>
            <DateInput
              width="200px"
              label="Search From"
              date={from}
              dateFormat="default"
              isStatic={false}
              onChange={(from) => this.setState({ from }, this.searchOrders)}
              clearDate={() =>
                this.setState({ from: '', searchPage: 0 }, this.searchOrders)
              }
            />
            <XSpacing width="20px" />
            <DateInput
              width="200px"
              label="Search To"
              date={to}
              dateFormat="default"
              isStatic={false}
              onChange={(to) =>
                this.setState({ to: to.endOf('day') }, this.searchOrders)
              }
              clearDate={() =>
                this.setState({ to: '', searchPage: 0 }, this.searchOrders)
              }
            />
            <XSpacing width="20px" />
            <Input
              label="Search Order Number"
              marginBottom="0"
              width="200px"
              type="text"
              value={search}
              onChange={(e) =>
                this.setState(
                  { search: e.target.value, searchPage: 0 },
                  this.searchAfterTimeout,
                )
              }
            />
            <XSpacing width="20px" />
          </FlexContainer>

          <YSpacing height="20px" />

          <FlexContainer flexDirection="column">
            <Label>Order Type</Label>
            <FlexContainer justifyContent="space-between" width="60%">
              {allOrderTypes.map((type) => (
                <div key={type.text}>
                  <Checkbox
                    label={type.text}
                    checked={
                      orderTypes
                        ? Boolean(
                            orderTypes.find((filter) => filter === type.value),
                          )
                        : false
                    }
                    onChange={() => this.onChangeOrderType(type)}
                  />
                  <XSpacing width="20px" />
                </div>
              ))}
            </FlexContainer>
            <YSpacing height="20px" />
          </FlexContainer>

          <YSpacing height="20px" />
          {selectedClient && contactName ? (
            <FlexContainer
              justifyContent="space-between"
              flexDirection="column"
            >
              <ModalSectionTitle>
                Search Orders ({unpaidOrders.length || 0})
              </ModalSectionTitle>
              {existingInvoice && unpaidOrders.length == 0 && (
                <Button
                  label="Search Orders to Add"
                  onClick={() =>
                    this.setState({ existingInvoice: false }, this.searchOrders)
                  }
                />
              )}

              <YSpacing height="20px" />

              {this.state.allOrdersLoading ? (
                <LoadingIndicator />
              ) : (
                <React.Fragment>
                  {unpaidOrders.length > 0 && (
                    <Button
                      label="Select all Orders"
                      onClick={this.onSelectAllOrders}
                    />
                  )}
                  <YSpacing height="4px" />
                  <Table
                    headings={[
                      'Order Number',
                      'Order Date',
                      'Effective Date',
                      'Due Date',
                      'Headcount',
                      'Order Total',
                      'Total Paid',
                      'Total Unpaid',
                      'Batch Unpaid',
                      '% Accounted',
                    ]}
                  >
                    {unpaidOrders.map((order) => this.unpaidOrderTitle(order))}
                  </Table>
                  {unpaidOrders.length <= 0 && orders.length > 0 && (
                    <p>
                      You have added all orders from this page to the Invoice.
                    </p>
                  )}
                  {unpaidOrders.length >= 0 && (
                    <FlexContainer
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="row"
                      marginTop="4px"
                    >
                      <XSpacing width="20px" />
                      <Button
                        label="<"
                        disabled={searchPage <= 0}
                        onClick={() =>
                          this.setState(
                            { searchPage: searchPage - 1 },
                            this.searchOrders,
                          )
                        }
                      />
                      <XSpacing width="20px" />
                      <Label>{`Page: ${searchPage + 1} of ${
                        pageCount + 1
                      }`}</Label>
                      <XSpacing width="20px" />
                      <Button
                        label=">"
                        disabled={searchPage === pageCount}
                        onClick={() =>
                          this.setState(
                            { searchPage: searchPage + 1 },
                            this.searchOrders,
                          )
                        }
                      />
                    </FlexContainer>
                  )}
                </React.Fragment>
              )}
              <YSpacing height="20px" />
              <DividerLine height="3px" />
              <YSpacing height="10px" />

              <OrderFormSectionTitle>
                <span>3</span>Orders, Payments & Transactions
              </OrderFormSectionTitle>
              <YSpacing height="20px" />
              <ModalSectionTitle>
                Invoice Orders ({invoicedOrders.length})
              </ModalSectionTitle>
              <p>
                % Accounted includes all amounts for an order that are either
                paid or unpaid on a batch invoice that is not resolved
              </p>
              <YSpacing height="20px" />
              <Table width="80%">
                {this.renderTableHeadings()}
                {invoicedOrders.map((order) => this.renderOrder(order))}
                {this.renderOrdersTotalRow(invoicedOrders)}
              </Table>
            </FlexContainer>
          ) : (
            <p>Please select a client and a contact to see orders.</p>
          )}
          <YSpacing height="20px" />
          <YSpacing height="20px" />
          <ModalSectionTitle>
            {' '}
            Unpaid Balances ({unpaidPayments.length})
          </ModalSectionTitle>
          <YSpacing height="20px" />
          {unpaidPayments.length > 0 && this.renderUnpaidPayments()}
          <YSpacing height="5px" />
          <Button
            disabled={invoicedOrders.every(
              (o) =>
                unpaidPayments.some(
                  (p) => p.paymentFor && p.paymentFor.id === o.id,
                ) || o.isPaid,
            )}
            label="Add Ad-hoc Balance"
            onClick={this.handleAdHocPayment}
          />
          <YSpacing height="20px" />
          {unpaidPayments.length > 0 && this.renderPaymentMethods()}
          <YSpacing height="20px" />
          <ModalSectionTitle>
            Transactions ({transactions ? transactions.length : 0})
          </ModalSectionTitle>
          <YSpacing height="20px" />
          {transactions && transactions.length > 0 && this.renderTxnTable()}
          <YSpacing height="20px" />

          {rootClient && (
            <LabelInfo
              label={`${rootClient.name}'s Net Payment Terms`}
              value={NetPaymentDays[rootClient.dueDateOffset]}
            />
          )}
          <YSpacing height="20px" />
          {invoice && (
            <FlexContainer>
              <LabelInfo label="Tip" value={`$${tip.toFixed(2)}`} />
              <XSpacing width="20px" />
              <LabelInfo
                label={'Total Paid'}
                value={`$${totalPaid.toFixed(2)}`}
              />
              <XSpacing width="20px" />
              <LabelInfo label={'Total Due'} value={`${totalDue.toFixed(2)}`} />
              <XSpacing width="20px" />
              {rootClient && invoice && (
                <>
                  <AuthorizedInteractable
                    roles={[
                      'master admin',
                      'finance',
                      'accounting',
                      'sales rep',
                      'sales lead',
                    ]}
                  >
                    <DateInput
                      label="Effective Date Override"
                      date={calcEffectiveDate(invoice)}
                      onChange={(date) =>
                        this.onUpdateEffectiveDateOverride(date)
                      }
                      hideClearDate={true}
                    />
                  </AuthorizedInteractable>
                  <XSpacing width="20px" />
                  <LabelInfo
                    label={'Due Date'}
                    value={calculateDueDate(rootClient, invoice).format(
                      'MM/DD/YYYY',
                    )}
                  />
                  <TooltipModal
                    unicode="&#9432;"
                    width="400px"
                    information={
                      "The invoice's effective date (or invoice date) is defaulted as the creation date unless overridden. The due date is determined by adding the net payment days to invoice's effective date."
                    }
                    marginTop="15px"
                  />
                </>
              )}
            </FlexContainer>
          )}
          <FlexContainer width="75%" alignItems="center" justifyContent="start">
            <FlexContainer width="30%">
              <Checkbox
                label={'Invoice is Final'}
                checked={isFinal}
                onChange={this.onSelectIsFinal}
                marginBottom="10px"
                marginTop="10px"
                width="50%"
              />
              {wasFinal && markFinalReason && (
                <TooltipModal
                  marginTop="15px"
                  color="#FF0000"
                  unicode="&#9432;"
                  information={`This invoice will not be seen on the Admin dashboard because ${markFinalReason}`}
                />
              )}
            </FlexContainer>
            <FlexContainer width="70%" alignItems="center">
              <AuthorizedInteractable
                roles={['master admin', 'finance', 'sales rep', 'sales lead']}
              >
                <Checkbox
                  label="Resolved"
                  checked={isResolved}
                  onChange={this.onSelectIsResolved}
                  marginBottom="10px"
                  marginTop="10px"
                  width="15%"
                />
              </AuthorizedInteractable>
              {resolvedAt && resolvedByEmail && (
                <p>
                  <strong>{`${isResolved ? 'Resolved' : 'Unresolved'}`}</strong>{' '}
                  by{' '}
                  {`${resolvedByEmail} on ${Moment(resolvedAt).format(
                    'dddd, MMMM Do YYYY, h:mm:ss a',
                  )}`}
                </p>
              )}
            </FlexContainer>
          </FlexContainer>
          <YSpacing height="10px" />
          <FlexContainer justifyContent="flex-end" alignItems="center">
            <AuthorizedInteractable roles={['master admin', 'finance']}>
              <Button
                label="Add / Edit Custom Batch Payment(s)"
                onClick={() => this.onChangeModal('showCustomPayModal')}
                disabled={allOrdersFullyPaid}
              />
            </AuthorizedInteractable>
            <XSpacing width="10px" />
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <Button
                onClick={() =>
                  this.setState({ isLoading: true }, this.onSaveInvoice)
                }
                label="Save Invoice"
              />
            )}
            <XSpacing width="10px" />
            {transactions && (
              <AuthorizedInteractable roles={['master admin', 'finance']}>
                <Button
                  onClick={() => this.onChangeModal('showVoidModal')}
                  label="Void an Invoice Transaction"
                  disabled={!voidableTxns.length}
                />
              </AuthorizedInteractable>
            )}
            <XSpacing width="10px" />
            {transactions && payments && (
              <AuthorizedInteractable roles={['master admin', 'finance']}>
                <Button
                  onClick={() => this.onChangeModal('showRefundModal')}
                  label="Refund an Order On Invoice"
                  disabled={!refundableTxns.length}
                />
              </AuthorizedInteractable>
            )}
          </FlexContainer>
        </Modal>
      )
    }
  }
}

InvoicesModal.propTypes = {
  arClientId: PropTypes.string,
  headquarter: PropTypes.number,
  invoice: PropTypes.object,
  locale: PropTypes.string,
  preloadOrders: PropTypes.array,
  uri: PropTypes.string,
  user: PropTypes.object,

  searchOrderPageCount: PropTypes.func,
  confirmationModal: PropTypes.func,
  checkInvoiceTxnStatuses: PropTypes.func,
  deleteVirtualExperience: PropTypes.func,
  displayWarningMessage: PropTypes.func,
  getAccountingClient: PropTypes.func,
  getClient: PropTypes.func,
  getOrder: PropTypes.func,
  loadingIndicator: PropTypes.func,
  hideModal: PropTypes.func,
  isAuthorized: PropTypes.func,
  isAuthorizedWtihAll: PropTypes.func,
  newInvoice: PropTypes.func,
  newRemovedPayment: PropTypes.func,
  newOrderPayment: PropTypes.func,
  newBuyer: PropTypes.func,
  payInvoice: PropTypes.func,
  saveInvoice: PropTypes.func,
  searchOrders: PropTypes.func,
  searchClients: PropTypes.func,
  searchInvoices: PropTypes.func,
  searchSalesReps: PropTypes.func,
  updateInvoices: PropTypes.func,
}

const TotalRow = styled.tr`
  td {
    font-weight: bold;
    font-size: 16px;
  }
  p {
    color: ${colors.blue400};
  }
`

export default InvoicesModal
