export const SET_GROUP_ORDER_PAGE = 'SET_GROUP_ORDER_PAGE'
export const CLEAR_GROUP_ORDER_PAGE = 'CLEAR_GROUP_ORDER_PAGE'
export const SET_CLIENT_SETTINGS = 'SET_CLIENT_SETTINGS'

//change vairable name
export const clearGroupOrderPage = () => ({ type: CLEAR_GROUP_ORDER_PAGE })
export const setGroupOrderPage = (groupOrderPage) => ({
  type: SET_GROUP_ORDER_PAGE,
  groupOrderPage,
})

export const setClientSettings = (clientSettings) => ({
  type: SET_CLIENT_SETTINGS,
  clientSettings,
})
