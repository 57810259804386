import React, { useEffect, useMemo, useCallback, useState } from 'react'
import Moment from 'moment-timezone'

import { Button } from '@components/common/form'
import Table from '@components/common/Table'

import {
  ClientReportingProgram,
  ApiDinerProfile,
  LoadClientReportingProgramsParams,
  ClientSettings,
} from '@types'

import { colors } from '../../../../constants'

interface ReportingProgramsProps {
  account: { id: string; pin: number }
  clientReportingPrograms: ClientReportingProgram[]
  clientSettings: ClientSettings[]
  loadClientReportingPrograms: (
    params: LoadClientReportingProgramsParams,
  ) => Promise<ClientReportingProgram[]>
  loadDinerProfiles: (
    params: Record<string, string | string[] | number[] | null>,
  ) => Promise<ApiDinerProfile[]>
  editClientReportingProgram: (reportingProgram: ClientReportingProgram) => void
  searchClientSettings: (params: {
    clientPin: string
  }) => Promise<ClientSettings[]>
}

const ReportingPrograms: React.FC<ReportingProgramsProps> = ({
  account,
  clientReportingPrograms,
  clientSettings,

  loadClientReportingPrograms,
  loadDinerProfiles,
  editClientReportingProgram,
  searchClientSettings,
}) => {
  const [dinerProfiles, setDinerProfiles] = useState<ApiDinerProfile[]>([])

  useEffect(() => {
    const { id, pin } = account

    const loadReportingPrograms = async () => {
      await loadClientReportingPrograms({ clientId: id })
    }
    const loadClientSettings = async () => {
      await searchClientSettings({
        clientPin: pin.toString(),
      })
    }

    loadReportingPrograms()
    loadClientSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const dinerProfilesIds = useMemo(() => {
    const uniqueIds = new Set<string>()

    clientReportingPrograms.forEach((reportingProgram) => {
      reportingProgram.dinerProfileIds.forEach((id) => {
        uniqueIds.add(id)
      })
    })

    return Array.from(uniqueIds)
  }, [clientReportingPrograms])

  const clientSettingsMap = useMemo(() => {
    return clientSettings.reduce(
      (acc, setting) => {
        acc[setting.id] = setting

        return acc
      },
      {} as Record<string, ClientSettings>,
    )
  }, [clientSettings])

  const _loadDinerProfiles = useCallback(async () => {
    const profiles = await loadDinerProfiles({ ids: dinerProfilesIds })
    setDinerProfiles(profiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dinerProfilesIds])

  useEffect(() => {
    _loadDinerProfiles()
  }, [_loadDinerProfiles])

  const onAddNewReportingProgram = () => {
    editClientReportingProgram({
      clientId: account.id,
      dinerProfileIds: [] as string[],
      clientSettingsIds: [] as string[],
    } as ClientReportingProgram)
  }

  const onEditReportingProgram = (reportingProgram: ClientReportingProgram) => {
    editClientReportingProgram({ ...reportingProgram })
  }

  return (
    <div>
      <div className="mb-5">
        <Button
          testId={'add-new-reporting-program'}
          label="Add New Reporting Program"
          backgroundColor={colors.gray400}
          onClick={onAddNewReportingProgram}
        />
      </div>
      <div className="w-full p-2 rounded-lg bg-white">
        <Table>
          <tr>
            <th>Reporting Program Name</th>
            <th>Diner Profiles</th>
            <th>Group Order Locations</th>
            <th>Is Default?</th>
            <th>Last Updated By</th>
          </tr>
          {clientReportingPrograms.map((reportingProgram) => (
            <ReportingProgramRow
              key={reportingProgram.id}
              clientSettingsMap={clientSettingsMap}
              dinerProfiles={dinerProfiles.filter((profile) =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                reportingProgram.dinerProfileIds.includes(profile.id!),
              )}
              reportingProgram={reportingProgram}
              onEdit={onEditReportingProgram}
            />
          ))}
        </Table>
      </div>
    </div>
  )
}

interface ReportingProgramRowProps {
  reportingProgram: ClientReportingProgram
  dinerProfiles: ApiDinerProfile[]
  clientSettingsMap: Record<string, ClientSettings>
  onEdit: (reportingProgram: ClientReportingProgram) => void
}

const ReportingProgramRow: React.FC<ReportingProgramRowProps> = ({
  reportingProgram,
  dinerProfiles,
  clientSettingsMap,
  onEdit,
}) => {
  const settingsName = (clientSettings?: ClientSettings) => {
    if (!clientSettings) {
      return 'Unknown Location'
    }

    return clientSettings.name === '' ? 'Default Location' : clientSettings.name
  }

  return (
    <tr className="cursor-pointer" onClick={() => onEdit(reportingProgram)}>
      <td>{reportingProgram.name}</td>
      <td>
        {dinerProfiles.length > 0 ? (
          dinerProfiles.map((profile, i) => (
            <p key={`diner-profile-${i}`}>{profile.name}</p>
          ))
        ) : (
          <p>No Diner Profiles Added</p>
        )}
      </td>
      <td>
        {reportingProgram.clientSettingsIds.length > 0 ? (
          reportingProgram.clientSettingsIds.map((id, i) => (
            <p key={`client-setting-${i}`}>
              {settingsName(clientSettingsMap[id])}
            </p>
          ))
        ) : (
          <p>No Locations Added</p>
        )}
      </td>
      <td>{reportingProgram.isDefault ? 'Yes' : 'No'}</td>
      <td>
        <p>{reportingProgram.lastUpdatedBy || 'Unknown'}</p>
        <p>{Moment(reportingProgram.updatedAt).format('MM/DD/YYYY h:mm a')}</p>
      </td>
    </tr>
  )
}

export default ReportingPrograms
