import { connect } from 'react-redux'
import { ReportingPrograms } from '@components/account/accountSections'
import coordinators from '@coordinators'
import services from '@services'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'
import {
  ClientReportingProgram,
  Account,
  ClientSettings,
  LoadClientReportingProgramsParams,
} from '@types'

interface State {
  account: Account
  user: { name: string }
  clientReportingPrograms: ClientReportingProgram[]
  clientSettings: ClientSettings[]
}

const mapStateToProps = (state: State) => {
  const { account, clientReportingPrograms, user, clientSettings } = state

  return {
    account,
    currentUser: user,
    clientSettings,
    clientReportingPrograms,
  }
}

const mapDispatchToProps = () => {
  const { RestService, AccountService, UIService, GroupOrdersService } =
    services
  const {
    LoadClientReportingPrograms,
    EditClientReportingProgram,
    SearchClientSettings,
    LoadDinerProfiles,
  } = coordinators
  const { pResponseGroupOrderClientSettings } = presenters.Api

  const loadClientReportingPrograms = LoadClientReportingPrograms({
    RestService,
    AccountService,
    HandleError,
  }) as (
    params: LoadClientReportingProgramsParams,
  ) => Promise<ClientReportingProgram[]>

  const loadDinerProfiles = LoadDinerProfiles({
    RestService,
    HandleError,
  })

  const editClientReportingProgram = EditClientReportingProgram({
    AccountService,
    UIService,
  })

  const searchClientSettings = SearchClientSettings({
    RestService,
    GroupOrdersService,
    HandleError,
    pResponseGroupOrderClientSettings,
  })

  return {
    loadClientReportingPrograms,
    loadDinerProfiles,
    editClientReportingProgram,
    searchClientSettings,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportingPrograms)
